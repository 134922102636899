import React, { useState, useEffect, useMemo } from 'react'
import { Route, useRouteMatch, useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import axios from 'axios'
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { red } from '@material-ui/core/colors';

import Toast from '../toast'
import Spinner from '../spinner'
import BoughtQty from '../bought-qty'
import ShoppingListItem from '../shopping-list-item'

import "./shopping-list.scss"

const ShoppingList = ( { isLongPress, setIsLongPress, setDeleteFunction, selectedDelete, setSelectedDelete, setNewCartItems, user } ) => {
  const [ search, setSearch ] = useState("")
  const [ supplier, setSupplier ] = useState("*")
  const [ storeSection, setStoreSection ] = useState("")
  const [ sorter, setSorter ] = useState("A to Z")
  const [ selected, setSelected ] = useState(null)
  const [ isOpen, setIsOpen ] = useState(false)
  const [ toastMessage, setToastMessage ] = useState("")
  const [ isRefetchingEnabled, setIsRefetchingEnabled ] = useState(true)
  const { data, isFetchedAfterMount } = useQuery(
    'shoppingList', 
    fetchShoppingList, 
    { 
      enabled: isRefetchingEnabled,
      placeholderData: { products: [] },
      // refetchInterval: 1000,
      refetchOnReconnect: false
    }
  )

  const storeSections = useMemo(() => [
    ...new Set(
      data.products
        .filter(({ storeSection }) => storeSection)
        .map(({ storeSection }) => storeSection)
    )
  ], [data.products])

  const suppliers = useMemo(() => {
    if (!data?.products) {
      return [];
    }
  
    const uniqueSuppliers = new Set();
    let hasNoSupplier = false;
  
    data.products.forEach((product) => {
      if (product.supplier) {
        if (Array.isArray(product.supplier)) {
          product.supplier.forEach((supplier) => {
            if (typeof supplier === "string") {
              supplier
                .split(",")
                .map((s) => s.trim())
                .filter((s) => s && s !== "*")
                .forEach((s) => uniqueSuppliers.add(s));
            }
          });
        } else if (typeof product.supplier === "string") {
          product.supplier
            .split(",")
            .map((s) => s.trim())
            .filter((s) => s && s !== "*")
            .forEach((s) => uniqueSuppliers.add(s));
        }
      } else {
        hasNoSupplier = true;
      }
    });
  
    return Array.from(uniqueSuppliers).sort((a, b) => a.localeCompare(b));
  }, [data?.products]);
 
  const history = useHistory()
  const match = useRouteMatch('/shopping-list/:sellerSku/:ext') || useRouteMatch('/shopping-list/:sellerSku')

  async function fetchShoppingList () {
    const response = await axios.get('/api/v1/inventory/shopping-list')

    return response.data
  }

  function listFilter (product) {
    const keysToFilter = ["fnsku", "sellerSku", "title", "asin"]
    const lowerCaseSearchTerm = search.toLowerCase()
    const isInSearchResult = keysToFilter.some(key => {
      const lowerCaseKeyValue = product[key]?.toLowerCase()
      return lowerCaseKeyValue?.includes(lowerCaseSearchTerm)
    })

    const matchesStoreSection = storeSection ? product?.storeSection === storeSection : true

    const supplierMatch =
      supplier === '*' ||
      (supplier === 'no-supplier' &&
        (!product.supplier ||
          (Array.isArray(product.supplier) && product.supplier.length === 0))) ||
      (Array.isArray(product.supplier) &&
        product.supplier.some((prodSupplier) => {
          if (typeof prodSupplier === 'string') {
            const trimmedSupplier = prodSupplier.trim();
            if (trimmedSupplier !== '') {
              return trimmedSupplier.split(',').some((s) => s.trim() === supplier);
            }
          }
          return false;
        })) ||
      (typeof product.supplier === 'string' &&
        product.supplier.trim() !== '' &&
        product.supplier.trim() === supplier);

    return isInSearchResult && matchesStoreSection && supplierMatch;
  }

  const handleSort = () => {
    let productsList = data.products;
   
    if (sorter === 'Z to A') {
      productsList.sort((a, b) => {
        if(a.title < b.title) { return -1; }
        if(a.title > b.title) { return 1; }
        return 0;
      })
     
    } else {
      productsList.sort((a, b) => {
        if(b.title < a.title) { return -1; }
        if(b.title > a.title) { return 1; }
        return 0;
      })
     
    }

    setIsRefetchingEnabled(false)
    setSorter(sorter === "A to Z" ? "Z to A" : "A to Z" )
    
  }

  function openToastWithMessage (message) {
    setIsOpen(true)
    setToastMessage(message)
  }

  useEffect(() => {
    if (data.products.length > 0 && match?.params.sellerSku) {
      const product = data.products.find(product => match.params.ext ? product.sellerSku === `${match.params.sellerSku}/${match.params.ext}` || product.sellerSku === `#${match.params.sellerSku}/${match.params.ext}` : product.sellerSku === match.params.sellerSku || product.sellerSku === `#${match.params.sellerSku}`)
      
      setSelected(product ?? null)

      if (!product) {
        const error = `Product with seller SKU of "${match.params.sellerSku}" is not found in the shopping list.`

        history.push("/shopping-list")
        openToastWithMessage(error)
      }
    }

  }, [data.products])

  return (
    <>
      {
        selected !== null
        ? <Route 
            path={`/shopping-list/:sellerSku`} 
            render={p => (
              <BoughtQty 
                {...p} 
                selected={selected}
                setSelected={setSelected}
                isRefetchingEnabled={isRefetchingEnabled}
                setIsRefetchingEnabled={setIsRefetchingEnabled}
                isLongPress={isLongPress}
                setIsLongPress={setIsLongPress}
                setDeleteFunction={setDeleteFunction} 
                selectedDelete={selectedDelete} 
                setSelectedDelete={setSelectedDelete}
                list={data.products}
                setNewCartItems={setNewCartItems}
                user={user}
              />
            )}
          />
        : <div className="shopping-list-page">
            <div className="controls">
              <input
                type="search" className="search" onInput={e => setSearch(e.target.value)}
                value={search} placeholder="Search for Title, ASIN, FNSKU, or Seller SKU"
              />
              <div className="filters">
                <div className="filter-field">
                  <label>Supplier</label>
                  <select value={supplier} onChange={e => setSupplier(e.target.value)}>
                    <option value="*">(All Suppliers)</option>
                    <option value="no-supplier">No Supplier</option>
                    {suppliers.map(supplier => (
                      <option value={supplier} key={supplier}>{supplier}</option>
                    ))}
                  </select>
                </div>
                <div className="filter-field">
                  <label>Store Section</label>
                  <select value={storeSection} onChange={e => setStoreSection(e.target.value)}>
                    <option value="">(All Store Sections)</option>
                    {storeSections.sort().map(storeSection => (
                      <option value={storeSection} key={storeSection}>{storeSection}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className='sort'>
                <div className='sort-by'>Sort by: <span onClick={handleSort}>{sorter}<ImportExportIcon className='sort-icon' style={{ color: red[500] }} fontSize="small" /></span></div>
                <div className='sort-result'>Result: <span>{data.products.filter(listFilter).length}</span></div>
              </div>
            </div>
            <div className="shopping-list">
              {
                isFetchedAfterMount
                ? data.products.length
                  ? data.products.filter(listFilter).map((product, i) => (
                      <ShoppingListItem
                        product={product}
                        setSelected={setSelected}
                        key={i}
                        linkTo="shopping-list"
                      />
                    ))
                  : <div className="shopping-list-empty">
                      <p>You have no products in your shopping list.</p>
                      <p>Once there's a product with a buy quantity,<br/>it will show up here.</p>
                    </div>
                : <Spinner isCentered={true} />
                
              }
            </div>
          </div>
      }
      <Toast 
        isOpen={isOpen} handleClose={() => setIsOpen(false)} type="error" 
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {toastMessage}
      </Toast>
    </>
  )
}

export default ShoppingList
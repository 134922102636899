import axios from 'axios'
import { useQuery } from 'react-query'
import moment from 'moment'
import 'moment-timezone'

const fetchBuyListItems = (params) => {
    return axios({
        url:'/api/v1/inventory/buyListMovements',
        method:'GET',
        params:params
    })
}

const fetchCartJournal = (buyListId) => ({pageParam = 1})=> {
    return axios.get(`/api/v1/cart-journal/by-buy-list/${buyListId}`)
}

const formatData = (data, user) => {
    const IM = data?.data?.filter(fb => fb.product).map(({ inQuantity, outQuantity,otherDetails, createdAt, product, sellerSku, user, message, isShipped }) => {
      const {
        asin,
        title,
        customTitle,
        listPrice,
        supplier,
        notesSection,
        forUpdate,
        imageUrl,
        decidedBuyQuantity,
        isAlwaysBuyList
        } = product;
        const costUnit = +otherDetails.costPrice || 0;
        const quantity = inQuantity??0-outQuantity??0;
        const condition = otherDetails.condition;
        const purchaseDate = createdAt;
        const msku = sellerSku;
        const salesTax = 0;
        const discount = 0;
        const expirationDate = otherDetails.expiryDate;
        const taxCode = otherDetails.taxCode;
        const notes = notesSection || '';
        const trimNotes = notes.trim().replace(/[\n\r]+/g, ' ');

        let supp = supplier;
        if(supplier === 'N/A' || supplier === undefined) {
        supp = '';
        }

        return {asin, title, customTitle, costUnit, listPrice, quantity, purchaseDate, supplier: supp, condition, msku, salesTax, discount, expirationDate, notes: trimNotes, taxCode, user, forUpdate, message, isShipped, imageUrl, decidedBuyQuantity, isAlwaysBuyList};
    })
    const userTimezone = user.timezone;

    const sortRows = IM?.slice().sort((a, b) => new Date(b.purchaseDate) - new Date(a.purchaseDate)).map((r) => {
        const purchaseDate = userTimezone ? moment(r?.purchaseDate).tz(userTimezone).format('lll') : moment(r?.purchaseDate).local().format('lll');
        let expirationDate = null
        if(r.expirationDate){
        expirationDate = moment(r.expirationDate).local().format('L');
        }

        return {...r, purchaseDate, expirationDate};
    })

    return sortRows;
}

export const useBuyLitsItems = (userId, buyListId, user) => {
    const params = {userId, buyListId }
    return useQuery(buyListId, ()=>fetchBuyListItems(params),{
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        select: (data)=>formatData(data, user)
    })
}

export const useCartJournalItems = (buyListId) => {
    return useQuery(`cart-journal-${buyListId}`, ()=>fetchCartJournal(buyListId))
}
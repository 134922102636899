import React from 'react'
import { CloudOff } from '@material-ui/icons'

import './offline-page.scss'

const OfflinePage = ({ handleReconnect }) => {
  return (
    <main className="offline-page">
      <CloudOff />
      <h1>You are currently offline.</h1>
      <p>Please check your internet connection.</p>
      <button className="reconnect" onClick={handleReconnect}>
        Reconnect
      </button>
    </main>
  )
}

export default OfflinePage
import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'

import { Assignment, Dashboard, Settings, Person, ExitToApp, ExpandLess, AssignmentTurnedIn } from '@material-ui/icons'

import './side-nav.scss'

const SideNav = ({ state, handlers }) => {
  const { user, isUserMenuOpen, newCartItems } = state
  const { handleLogout, handleUserMenuToggle } = handlers

  const { path: pathInventory } = ( useRouteMatch('/inventory/:productId') ) ?? {}
  const selectedInventory = localStorage.getItem('selectedInventory')

  return (
    <nav className="side-nav">
      <div className="nav-contents">
        <div className="nav-links">
          <NavLink to="/dashboard" activeClassName="active">
            <Dashboard /> Dashboard
          </NavLink>
          <NavLink to={selectedInventory ? pathInventory ? `/inventory` : `/inventory/${selectedInventory}` : `/inventory` } activeClassName="active">
            <AssignmentTurnedIn /> Inventory
          </NavLink>
          <NavLink to="/shopping-list" activeClassName="active">
            <Assignment /> Shopping List
          </NavLink>
          <NavLink to="/settings" activeClassName="active">
            <Settings /> Settings
          </NavLink>
        </div>
        <div className={`user-menu${isUserMenuOpen ? " is-open" : ""}`}>
          <div className="user-dropdown-contents">
            <button onClick={handleLogout}>
              <ExitToApp /> Logout
            </button>
          </div>
          <button className="user-dropdown-button" onClick={handleUserMenuToggle}>
            <Person />
            <span>{user?.name}</span>
            <ExpandLess />
          </button>
        </div>
      </div>
    </nav>
  )
}

export default SideNav
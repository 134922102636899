import React, { useEffect } from 'react'

import './child-row.scss'

const ChildRow = ({ child, packQty }) => {
    const { title, imageUrl, asin, localInventory } = child.child
    const { quantity } = child

    const thumbnail = imageUrl
    ? `https://images-na.ssl-images-amazon.com/images/I/${imageUrl.split(".")[0]}._AC_US96_.jpg`
    : "/assets/images/no-image-available.svg"

    // useEffect(() => {
    //     console.log(child.child)
    // }, [child.child])
    return (
        <>
            <div className="child-row">
                <img src={thumbnail} alt={`${title} - child item`}/>
                <div className="child-info">
                    <p className="title">{title}</p>
                    <div className="asin-container">
                        <p className="asin">{asin}</p>
                    </div>
                </div>
            </div>
            <div className="child-quantity-info">
                <div className="prop-group">
                    <p className="label">Current Inventory:</p>
                    <p className="value">{localInventory}</p>
                </div>
                <div className="prop-group">
                    <p className="label">Qty to be allocated:</p>
                    <p className="value">{packQty * quantity}</p>
                </div>
                <div className="prop-group">
                    <p className="label">Qty per parent:</p>
                    <p className="value">{quantity}</p>
                </div>
                <div className="prop-group">
                    <p className="label">Qty after packing:</p>
                    <p className="value">{localInventory - (packQty * quantity)}</p>
                </div>
            </div>
        </>
    )
}

export default ChildRow

import React, { useEffect, useState, useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'
import { CameraAlt } from '@material-ui/icons'
import { Tooltip } from '@material-ui/core'
import axios from 'axios'
import './inventory-item.scss'
import Toast from '../../toast'
import Quantities from './accordions/quantities'
import ThirtyDays from './accordions/thirtyDays'
import SixtyDays from './accordions/sixtyDays'
import NinetyDays from './accordions/ninetyDays'
import Cost from './accordions/cost'
import BuyBox from './accordions/buyBox'
import Row from './row'
import StoreSection from './row-edit/store-section'
import Supplier from './row-edit/supplier'
import { useQuery } from 'react-query'

import { calculateForQualifiedFBAInboundPlacementFee, calculateForQualifiedLowInventoryLeveFee, chooseDefaultCalculationPrice, GetProfitEstimations } from '../../../utilities/functions'
import BuyLink from './accordions/buyLink'

const InventoryItem = ({ inventory, setInventory, user }) => {
    const { productId } = useParams()
    const [product, setProduct] = useState(inventory.products.filter(inv => inv._id === productId)[0] ? inventory.products.filter(inv => inv._id === productId)[0] : {})
    const [openToast, setOpenToast] = useState(false)
    const [toastMsg, setToastMsg] = useState('')
    const [expanded, setExpanded] = useState(false)
    // const [fetching, setFetching] = useState(true)

    const fetchProductDetails = async() => {
        const { data } = await axios.get(`/api/v1/inventory/product-detail/${productId}`)
        return data;
    }

    const { data, isFetching } = useQuery(productId, fetchProductDetails, {refetchOnWindowFocus: 'always'})

    const [productCopy, setProductCopy] = useState({})

    const fulfilmentType = {
        Amazon: 'Amazon FBA',
        Merchant: 'Merchant FBM',
        Ebay: 'Ebay'
    }

    const thumbnail = product?.imageUrl
    // Fetch 96×96px image (even with 48×48px container) for better quality in high DPI devices
    ? `https://images-na.ssl-images-amazon.com/images/I/${product.imageUrl.split(".")[0]}._AC_US96_.jpg`
    // Else, just load the "No Image Available" placeholder
    : "/assets/images/no-image-available.svg"

    // useLayoutEffect(() => {
    //     // if (!product.title) {
    //         getProductDetails()
    //     // }
    // }, [productId])

    useEffect(()=> {
        if(data){
            const placementServiceFee = calculateForQualifiedFBAInboundPlacementFee(data, user);
            const lowInventoryLevelFee = calculateForQualifiedLowInventoryLeveFee(data);
            const {
                profitCalculatedBy,
                listPrice,
                buyBoxPrice,
                lowestOfferPrice,
                lowestFBAOfferPrice,
                costPrice,
                fee,
                fulfilmentType,
                shipBy,
                shippingRate,
                weight,
                enrolledInSnL,
                fbmFee,

                afnInboundWorkingQuantity,
                afnInboundShippedQuantity,
                afnInboundReceivingQuantity,
                afnResearchingQuantity,
                sellableQuantity,
                afnUnsellableQuantity,

                // for lilf, fba inbound psf and prep center fee
                exemptedFromLowInventoryLevelFee,
                prepCenterFees,

                // for reserved qty
                afnReservedQuantityCustOrder,
                afnReservedQuantityFcProc,
                afnReservedQuantityFcTrans,
                afnReservedQuantity,
                afnTotalQuantity,
            } = data
            const defaultPrice = chooseDefaultCalculationPrice({
                profitCalculatedBy,
                listPrice,
                buyBoxPrice,
                lowestOfferPrice,
                lowestFBAOfferPrice
            });
            const updated_data = GetProfitEstimations({
                costPrice,
                defaultPrice,
                fee,
                fulfilmentType,
                shipBy,
                shippingRate,
                prepCenterFees,
                weight,
                enrolledInSnL,
                fbmFee,
                lowInventoryLevelFee,
                onOffLowInventoryLvlFee: user?.onOffLowInventoryLvlFee ?? false,
                onOffFBAInboundPlacementServiceFee: user?.onOffFBAInboundPlacementServiceFee ?? false,
                placementServiceFee,
            });


            // for lilf, fba inbound psf and prep center fee
            const showLILF = lowInventoryLevelFee > 0 && (exemptedFromLowInventoryLevelFee == "No" || !exemptedFromLowInventoryLevelFee) && user?.onOffLowInventoryLvlFee;
            const showPrepCenterFees = prepCenterFees > 0;
            const showFBAIPSF = (placementServiceFee !== null && placementServiceFee > 0) && user?.onOffFBAInboundPlacementServiceFee;
            
            const LILF = showLILF ? lowInventoryLevelFee : 0;
            const FBAIPSF = showFBAIPSF ? placementServiceFee : 0;


            // for reserved qty
            const newAfnReservedQuantity = (afnReservedQuantityCustOrder ?? 0) + (afnReservedQuantityFcProc ?? 0) + (afnReservedQuantityFcTrans ?? 0);
            
            let newAfnTotalQuantity = 0;
      
            if (fulfilmentType != "Merchant") {
              newAfnTotalQuantity = (afnInboundWorkingQuantity ?? 0) + (afnInboundShippedQuantity ?? 0) + (afnInboundReceivingQuantity ?? 0) + newAfnReservedQuantity + (afnResearchingQuantity ?? 0);
            }
            
            newAfnTotalQuantity += (sellableQuantity ?? 0);

            setProduct({
                ...data,
                ...updated_data,
                defaultPrice,
                lowInventoryLevelFee: LILF,
                placementServiceFee: FBAIPSF,
                showLILF,
                showPrepCenterFees,
                showFBAIPSF,
                newAfnReservedQuantity,
                newAfnTotalQuantity,
            });
        }
    }, [data])

    useEffect(() => {
        document.querySelector('.content').style.backgroundColor = 'white'
        return () => {
            document.querySelector('.content').style.backgroundColor = 'var(--replen-light-bg)'
        }
    }, [document.querySelector('.content')])

    useEffect(() => {
        setProductCopy(product)
    }, [product])

    // const getProductDetails = async () => {
    //     const { data } = await axios.get(`/api/v1/inventory/product-detail/${productId}`)
    //     setProduct(data)
    //     setFetching(false)
    //     console.log('product fetched', data)
    // }

    const copyValue = (value, copiedField) => {
        navigator.clipboard.writeText(value)
        setToastMsg(`Succesfully copied ${copiedField} to clipboard`)
        setOpenToast(true)
    }

    const dateAdded = new Date(product?.openDate)?.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' })

    const handleCloseModal = () => setOpenToast(false)

    // onfocus={getProductDetails}

    return (
         product && data ?
            <div className="inventory-item">
            {openToast && <Toast isOpen={openToast} handleClose={handleCloseModal} duration={1500}> {toastMsg} </Toast> }
            <div className="item-preview">
                <img className="thumbnail" src={thumbnail} alt={product.title}/>
                <div className="title">{product.title}</div>
            </div>
            <div className="item-details-container">
                <div className="item-details">
                    <div className="asin item-detail">
                        <p className="label">ASIN</p>
                        <p className="value" onClick={() => copyValue(product.asin, 'ASIN')}>{product.asin}</p>
                    </div>
                    <div className="sku item-detail">
                        <p className="label">SKU</p>
                        <p className="value" onClick={() => copyValue(product.sellerSku, 'SKU')}>{product.sellerSku}</p>
                    </div>
                    <div className="marketplace item-detail">
                        <p className="label"></p>
                        <p className="value">
                            {fulfilmentType[product.fulfilmentType]}
                        </p>
                    </div>
                </div>
                <div className='icon-links'>
                    <a href={"https://lens.google.com/uploadbyurl?url=https://images-na.ssl-images-amazon.com/images/I/" + product.imageUrl} target="_blank">
                        <Tooltip title='Search by Google Lens' >
                        <CameraAlt className='camera-search'/>
                        </Tooltip>
                    </a>
                    <a href={`https://keepa.com/#!product/1-${product.asin}`} target="_blank" rel="noopener noreferrer">
                        <img src="/assets/images/keepa-icon.svg" />
                    </a>
                </div>
            </div>
            <div className="accordion-grid" style={{marginTop: '2rem'}}>
                <Quantities expanded={expanded} setExpanded={setExpanded} product={product} fetching={isFetching} productCopy={productCopy} setOpenToast={setOpenToast} setToastMsg={setToastMsg} setInventory={setInventory} inventory={inventory} />
            </div>
            <div className="accordion-flex">
                <ThirtyDays expanded={expanded} setExpanded={setExpanded} product={product} />
            </div>
            <div className="accordion-flex">
                <SixtyDays expanded={expanded} setExpanded={setExpanded} product={product} />
            </div>
            <div className="accordion-flex">
                <NinetyDays expanded={expanded} setExpanded={setExpanded} product={product} />
            </div>
            <div className="accordion-grid">
                <Cost expanded={expanded} setExpanded={setExpanded} product={product} setProduct={setProduct} fetching={isFetching} productCopy={productCopy} setOpenToast={setOpenToast} setToastMsg={setToastMsg} setInventory={setInventory} inventory={inventory} />
            </div>
            <div className="row">
                <Row label="your price" value={product.listPrice ? `$${product?.listPrice}` : 'N/A'} />
            </div>
            <div className="accordion-flex">
                <BuyBox expanded={expanded} setExpanded={setExpanded} product={product} />
            </div>
            <div className='accordion-flex'>
                <BuyLink expanded={expanded} setExpanded={setExpanded} product={product} ></BuyLink>
            </div>
            <div className="row-edit">
                <Supplier label="supplier" value={data?.supplier ? data.supplier : 'N/A'} product={data} setOpenToast={setOpenToast} setToastMsg={setToastMsg} inventory={inventory} setInventory={setInventory} fetching={isFetching}/>
            </div>
            <div className="row-edit">
                <StoreSection label="store section" value={data?.storeSection ? data.storeSection : 'N/A'} product={data} setOpenToast={setOpenToast} setToastMsg={setToastMsg} inventory={inventory} setInventory={setInventory} fetching={isFetching}/>
            </div>
            <div className="row">
                <Row label="status" value={product?.status ? product.status : 'N/A'} />
            </div>
            <div className="row">
                <Row label="date added" value={dateAdded ? dateAdded : 'N/A'} />
            </div>
            <div style={{paddingBottom: '2rem'}}></div>
        </div>
        : null
    )
}

export default InventoryItem

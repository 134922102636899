import React from 'react'
import { useHistory } from 'react-router-dom'
import './unresolved-list-item.scss'

const UnresolvedListItem = ({ row, setOpenModal, setItem }) => {
    const { title, imageUrl, asin, sellerSku, _id: childId } = row

    const history = useHistory()

    const onClickResolve = () => {
        setOpenModal(true)
        setItem(row)
    }

    const onClickPack = () => {
        history.push(`/unresolved-products/pack/${childId}`)
    }

    const thumbnail = imageUrl
    ? `https://images-na.ssl-images-amazon.com/images/I/${imageUrl.split(".")[0]}._AC_US96_.jpg`
    : "/assets/images/no-image-available.svg"

    return (
        <div className="grid">
            {/* Info */}
            <div className="product-info">
                <div className="product-image-title">
                    <img src={thumbnail} alt={title}/>
                    <p>{title}</p>
                </div>
                <div className="product-asin-sku">
                    <p>{asin}</p>
                    <p>{sellerSku}</p>
                </div>
            </div>
            {/* Actions */}
            <div className="actions">
                <button onClick={onClickPack}>Pack</button>
                <button onClick={onClickResolve} >Resolve</button>
            </div>
        </div>
    )
}

export default UnresolvedListItem

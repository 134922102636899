import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core' 
import { useRouteMatch, useHistory } from 'react-router-dom'

import axios from 'axios'

export default function ConfirmResolve({ open, setOpen, item, setRows, setIsLoading }) {
  const history = useHistory()
  const match = useRouteMatch('/unresolved-products/pack/:childId')

  const handleClose = () => {
    setOpen(false);
  };

  const handleResolve = () => {
    setIsLoading(true)
    handleClose()
    axios.post(`/api/v1/child-inventory/resolve/${item._id || item.childId}`)
         .then(res => {
            axios.get('/api/v1/child-inventory/unresolved')
            .then(({ data }) => {
                setRows(data.data)
                setIsLoading(false)
                if (match && match.path == '/unresolved-products/pack/:childId') {
                  history.push('/unresolved-products')
                }
            })
         })
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Resolve"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Resolve child product "{item.title}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus style={{color: 'var(--replen-orange)'}}>Disagree</Button>
          <Button onClick={handleResolve} style={{color: 'var(--replen-orange)'}}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

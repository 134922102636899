import React from 'react'
import ReactDOM from 'react-dom'
import Snackbar from '@material-ui/core/Snackbar'
import { Alert } from '@material-ui/lab'

import './toast.scss'

const Toast = ({ children, isOpen, anchorOrigin, handleClose, type = "success", duration = 6000 }) => {
  return ReactDOM.createPortal(
    <Snackbar className="toast" open={isOpen} autoHideDuration={duration} onClose={handleClose} anchorOrigin={anchorOrigin}>
      <Alert onClose={handleClose} severity={type} variant="filled">
        {children}
      </Alert>
    </Snackbar>,
    document.body
  )
}

export default Toast
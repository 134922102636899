import axios from 'axios'
import React, { useEffect, useState } from 'react'
import BuyListGroupSelection from '../buy-list-selection'
import Spinner from '../spinner'
import './default-buylist.scss'

const DefaultBuylist = ({user, selectedItem, setSelectedItem}) => {
    const [showBuyList, setShowBuyList] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    useEffect(()=>{
        const defBuylist = localStorage.getItem(`default-buylist-${user._id}`)
        if(defBuylist){
            const jsonBuyList = JSON.parse(defBuylist)
            validateBuyList(jsonBuyList._id)
        }
    },[])

    const validateBuyList = (buyListId) => {
        setIsLoading(true)
        axios.get(`/api/v1/buy-list-group/${buyListId}`)
        .then(res=>{
            if(res.status === 200){
                if(res?.data.isArchive === false){
                    setSelectedItem(res.data)
                    setIsLoading(false)
                }
            }
        })
        .finally(()=>{
            setIsLoading(false)
        })
    }

    const handleBuyListClick = (event) => {
        event.preventDefault()
        setShowBuyList(true)
    }

    const onBuyList = () => {
        if(selectedItem){
            localStorage.setItem(`default-buylist-${user?._id}`, JSON.stringify(selectedItem))
            setShowBuyList(false)
        }
    }

    return (
        <>
            <BuyListGroupSelection
                showBuyList={showBuyList}
                setShowBuyList={setShowBuyList}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                onBuyList={onBuyList}
            />
            {isLoading?<Spinner size={20} />:
            <button className='btn-buy-list' onClick={handleBuyListClick} >{selectedItem?selectedItem.name:'<Choose Buy List>'}</button>
            }
        </>
        )
}

export default DefaultBuylist
import React from 'react'
import { Link } from 'react-router-dom'
import { ChevronRight } from '@material-ui/icons'

import numberToUSD from '../../utilities/js/number-to-usd'

import './shopping-list-item.scss'

const ShoppingListItem = ({ product, setSelected, linkTo }) => {
  const { 
    imageUrl,
    
    fulfilmentType,
    afnReservedQuantityCustOrder,
    afnReservedQuantityFcProc,
    afnReservedQuantityFcTrans,
    afnInboundWorkingQuantity,
    afnInboundShippedQuantity,
    afnInboundReceivingQuantity,
    afnResearchingQuantity,
    sellableQuantity,
    afnUnsellableQuantity,
    afnTotalQuantity,
    
    decidedBuyQuantity, asin, supplier,
    costPrice, title, sellerSku, storeSection, isAlwaysBuyList, customTitle, isSellable,
  } = product
  
  // for reserved qty
  const newAfnReservedQuantity = (afnReservedQuantityCustOrder ?? 0) + (afnReservedQuantityFcProc ?? 0) + (afnReservedQuantityFcTrans ?? 0);
  
  let newAfnTotalQuantity = 0;

  if (fulfilmentType != "Merchant") {
    newAfnTotalQuantity = (afnInboundWorkingQuantity ?? 0) + (afnInboundShippedQuantity ?? 0) + (afnInboundReceivingQuantity ?? 0) + newAfnReservedQuantity + (afnResearchingQuantity ?? 0);
  }
  
  newAfnTotalQuantity += (sellableQuantity ?? 0);


  const thumbnail = imageUrl
    // Fetch 96×96px image (even with 48×48px container) for better quality in high DPI devices
    ? `https://images-na.ssl-images-amazon.com/images/I/${imageUrl.split(".")[0]}._AC_US96_.jpg`
    // Else, just load the "No Image Available" placeholder
    : "/assets/images/no-image-available.svg"

  const handleClick = () => setSelected(product)
  const isZeroBuyQty = decidedBuyQuantity === 0 || decidedBuyQuantity === null || decidedBuyQuantity === undefined
  const shoppingListItemClass = `shopping-list-item${isZeroBuyQty && !isAlwaysBuyList ? " zero-buy-qty" : ""}`

  const filteredSellerSku = sellerSku?.includes('#') ? sellerSku.replace('#', '') : sellerSku

  return (
    <Link to={`/${linkTo}/${encodeURIComponent(filteredSellerSku)}`} onClick={handleClick} className={shoppingListItemClass}>
      <img className="thumbnail" src={thumbnail} />
      <div className="title">{customTitle ?? title} {(!isSellable == undefined || isSellable == false)?"(Non Sellable)": ""}</div>
      <div className="props">
        {/* <div className="prop">
          <label>ASIN</label>
          <div>{asin}</div>
        </div> */}
        <div className="prop">
          <label>SKU</label>
          <div>{sellerSku}</div>
        </div>
        <div className="prop">
          <label>Cost</label>
          <div>{costPrice ? numberToUSD(costPrice ?? 0) : "N/A"}</div>
        </div>
        <div className="prop">
          <label>QTY</label>
          <div>{newAfnTotalQuantity}</div>
        </div>
        <div className="prop">
          <label>Supplier</label>
          <div>{Array.isArray(supplier) ? supplier.join(", ") : supplier ?? "N/A"}</div>
        </div>
        <div className="prop">
          <label>Store Section</label>
          <div>{storeSection ?? "N/A"}</div>
        </div>
      </div>
      <div className="buy-qty">
        <span>{isAlwaysBuyList ? "∞" : decidedBuyQuantity ? decidedBuyQuantity : 0}</span>
        <ChevronRight />
      </div>
    </Link>
  )
}

export default ShoppingListItem
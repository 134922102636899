import React, { useState, useEffect } from 'react'
import { ArrowRight } from '@material-ui/icons'
import ChildRow from './child-row'
import BuyListMergeSelection from '../../buy-list-merge-selection/buy-list-merge-selection'

import axios from 'axios'

import './parent-item.scss'

const ParentItem = ({ parent, setParents, setItem, setIsLoading, setChildInfo, childId }) => {
    const [expand, setExpand] = useState(false)
    // const [parentState, setParentState] = useState(parent)
    // const { title, asin, sellerSku, imageUrl, localInventory, decidedBuyQuantity, childItems, _id } = parentState
    const { title, asin, sellerSku, imageUrl, localInventory, decidedBuyQuantity, childItems, _id } = parent
    const [packQty, setPackQty] = useState(1)

    const childIds = childItems.map(child=>child.child._id)
    const [buyLists, setBuyLists] = useState([])
    const [showBuyListMerge, setShowBuyListMerge] = useState(false)

    const handleItemBuyList =async() => {
        const resp = await axios.get(`/api/v1/cart-journal/${[...childIds]}`)
        if(resp.status === 200){
            const uniqueBuyists = [...new Set(resp.data.map(list=>`${list.buyListId._id}||${list.buyListId.name}`))]
            setBuyLists(uniqueBuyists)
        }
    }

    useEffect(()=>{
        handleItemBuyList()
    },[])

    const thumbnail = imageUrl
    ? `https://images-na.ssl-images-amazon.com/images/I/${imageUrl.split(".")[0]}._AC_US96_.jpg`
    : "/assets/images/no-image-available.svg"

    const handlePacking = (buyListId) => {
        setIsLoading(true)
        axios.post(`/api/v1/child-inventory/pack/${sellerSku}`, { qty: packQty, buyListId })
             .then((res) => {
                axios.get(`/api/v1/child-inventory/child-info/${childId}`)
                     .then(({ data }) => {
                         setChildInfo(data)
                         setItem(data)
                         axios.get(`/api/v1/child-inventory/parent/${childId}?populateChild=1`)
                                         .then(({ data }) => {
                                             setParents(data)
                                            //  setParentState(data.filter(d => d._id == _id)[0])
                                             setIsLoading(false)
                                         })
                     })
             })
    }

    const handleBuyListMerge = event => {
        event.preventDefault()

        if(buyLists.length > 1){
            setShowBuyListMerge(true)
        }
        else if(buyLists.length ===1){
            const arrBuyList = buyLists[0].split('||')
            handlePacking(arrBuyList[0])
        }
        else{
            handlePacking(undefined)
        }
    }

    const isEnoughForPacking = childItems.every(({ child, quantity }) => {
        return child.localInventory >= (quantity * packQty)
      })

    const canPack = (packQty > 0) && isEnoughForPacking

    return (
        <div className="parent-item">
            <BuyListMergeSelection
            showBuyListMerge={showBuyListMerge}
            setShowBuyListMerge={setShowBuyListMerge}
            buyListArray={buyLists}
            handlePack={handlePacking}
            />
            <div className="parent-row">
                <img src={thumbnail} alt={`${title} - parent`}/>
                {/* Parent Info */}
                <div className="parent-info">
                    <p className="title">{title}</p>
                    <div className="parent-asin-sku">
                        <p>{asin}</p>
                        <p>{sellerSku}</p>
                    </div>
                </div>
                <ArrowRight className={expand ? 'expand-icon expand' : 'expand-icon'} onClick={() => setExpand(old => !old)}/>

                {/* Parent Info and Pack Section */}
                <div className="actions-section">
                    <div className="info action-pack">
                        <div className="info-container">
                            <p className="label">Local inv.:</p>
                            <p className="value">{localInventory || 0}</p>
                        </div>
                        <div className="info-container">
                            <p className="label">Buy Qty:</p>
                            <p className="value">{decidedBuyQuantity || 0}</p>
                        </div>
                    </div>

                    <div className="action action-pack">
                        <form onSubmit={handleBuyListMerge}>
                            <input type="number" min="0" step="1" value={packQty} onChange={e => setPackQty(e.target.value)} onFocus={e => e.target.select()} />
                            <button disabled={!canPack} className={!canPack ? 'disabled' : undefined}>Pack</button>
                        </form>
                    </div>
                </div>

                {/* Children Section */}
                <div className={expand ? 'children-section expand-children' : 'children-section'}>
                { childItems && childItems.length > 0 && childItems.map((child, index) => (
                    <ChildRow child={child} key={index} packQty={packQty} />
                )) }
                </div>

            </div>
        </div>
    )
}

export default ParentItem

const dateNow = new Date()

function round(n, precision = 0) {
  const factor = Math.pow(10, precision);
  return Math.round(n * factor) / factor;
}

export const calculateForQualifiedFBAInboundPlacementFee = (product, user) => {
  const { sizeTier, height, length, width, weight, asin} = product
  const dimensionalWeight = (length * width * height) / 139
  const shippingWeight = dimensionalWeight > weight? dimensionalWeight: weight

  let feeValue = null

  if(asin == 'B07639GFV7'){
    console.log(asin)
  }
  if(user?.inboundPlacement === 'minimal'){
    if(sizeTier === "Small Standard Size"){
      if(shippingWeight <=1){
        switch(user?.inboundRegion){
          case 'west':
            feeValue = 0.30
            break
          case 'central':
            feeValue = 0.26
            break
          case 'east':
            feeValue = 0.21
        }
      }
    } else if(sizeTier === "Large Standard Size"){
      if(shippingWeight <= 0.75){
        switch(user?.inboundRegion){
          case 'west':
            feeValue = 0.34
            break
          case 'central':
            feeValue = 0.28
            break
          case 'east':
            feeValue = 0.23
        }
      } else if(shippingWeight > 0.75 && shippingWeight <= 1.5){
        switch(user?.inboundRegion){
          case 'west':
            feeValue = 0.41
            break
          case 'central':
            feeValue = 0.33
            break
          case 'east':
            feeValue = 0.27
        }
      } else if(shippingWeight > 1.5 && shippingWeight <= 3){
        switch(user?.inboundRegion){
          case 'west':
            feeValue = 0.49
            break
          case 'central':
            feeValue = 0.38
            break
          case 'east':
            feeValue = 0.32
        }
      } else if(shippingWeight > 3 && shippingWeight <= 20){
        switch(user?.inboundRegion){
          case 'west':
            feeValue = 0.68
            break
          case 'central':
            feeValue = 0.51
            break
          case 'east':
            feeValue = 0.42
        }
      }
    } else if(sizeTier === "Large Bulky Size"){
      if(shippingWeight <= 5){
        switch(user?.inboundRegion){
          case 'west':
            feeValue = 2.30
            break
          case 'central':
            feeValue = 2.30
            break
          case 'east':
            feeValue = 2.30
          }
        }
      } else if(shippingWeight > 5 && shippingWeight <=12){
          switch(user?.inboundRegion){
            case 'west':
              feeValue = 2.70
              break
            case 'central':
              feeValue = 2.70
              break
            case 'east':
              feeValue = 2.70
          }
      } else if(shippingWeight > 12 && shippingWeight <= 28){
          switch(user?.inboundRegion){
            case 'west':
              feeValue = 3.40
              break
            case 'central':
              feeValue = 3.40
              break
            case 'east':
              feeValue = 3.40
          }
      } else if(shippingWeight > 28 && shippingWeight <= 42){
          switch(user?.inboundRegion){
            case 'west':
              feeValue = 4.40
              break
            case 'central':
              feeValue = 4.40
              break
            case 'east':
              feeValue = 4.40
          }
      } else if(shippingWeight > 42 && shippingWeight <= 50){
          switch(user?.inboundRegion){
            case 'west':
              feeValue = 5.20
              break
            case 'central':
              feeValue = 5.20
              break
            case 'east':
              feeValue = 5.20
          }
      }
  } else if (user?.inboundPlacement === 'partial'){
    if(sizeTier === "Small Standard Size"){
      if(shippingWeight <=1){
        switch(user?.inboundRegion){
          case 'west':
            if(user?.numberOfLocations === 2){
              feeValue = 0.21
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.15
            }
            break
          case 'central':
            if(user?.numberOfLocations === 2){
              feeValue = 0.21
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.15
            }
            break
          case 'east':
            if(user?.numberOfLocations === 2){
              feeValue = 0.16
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.12
            }
        }
      }
    } else if(sizeTier === "Large Standard Size"){
      if(shippingWeight <= 0.75){
        switch(user?.inboundRegion){
          case 'west':
            if(user?.numberOfLocations === 2){
              feeValue = 0.24
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.16
            }
            break
          case 'central':
            if(user?.numberOfLocations === 2){
              feeValue = 0.23
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.16
            }
            break
          case 'east':
            if(user?.numberOfLocations === 2){
              feeValue = 0.18
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.13
            }
        }
      } else if(shippingWeight > 0.75 && shippingWeight <= 1.5){
        switch(user?.inboundRegion){
          case 'west':
            if(user?.numberOfLocations === 2){
              feeValue = 0.28
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.20
            }
            break
          case 'central':
            if(user?.numberOfLocations === 2){
              feeValue = 0.26
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.19
            }
            break
          case 'east':
            if(user?.numberOfLocations === 2){
              feeValue = 0.21
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.15
            }
        }
      } else if(shippingWeight > 1.5 && shippingWeight <= 3){
        switch(user?.inboundRegion){
          case 'west':
            if(user?.numberOfLocations === 2){
              feeValue = 0.34
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.24
            }
            break
          case 'central':
            if(user?.numberOfLocations === 2){
              feeValue = 0.31
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.22
            }
            break
          case 'east':
            if(user?.numberOfLocations === 2){
              feeValue = 0.24
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.17
            }
        }
      } else if(shippingWeight > 3 && shippingWeight <= 20){
        switch(user?.inboundRegion){
          case 'west':
            if(user?.numberOfLocations === 2){
              feeValue = 0.48
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.33
            }
            break
          case 'central':
            if(user?.numberOfLocations === 2){
              feeValue = 0.42
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.29
            }
            break
          case 'east':
            if(user?.numberOfLocations === 2){
              feeValue = 0.32
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.23
            }
        }
      }
    } else if(sizeTier === "Large Bulky Size"){
      if(shippingWeight <= 5){
        switch(user?.inboundRegion){
          case 'west':
            if(user?.numberOfLocations === 2){
              feeValue = 1.33
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.55
            }
            break
          case 'central':
            if(user?.numberOfLocations === 2){
              feeValue = 1.33
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.55
            }
            break
          case 'east':
            if(user?.numberOfLocations === 2){
              feeValue = 1.33
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.55
            }
        }
      } else if(shippingWeight > 5 && shippingWeight <=12){
        switch(user?.inboundRegion){
          case 'west':
            if(user?.numberOfLocations === 2){
              feeValue = 1.57
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.65
            }
            break
          case 'central':
            if(user?.numberOfLocations === 2){
              feeValue = 1.57
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.65
            }
            break
          case 'east':
            if(user?.numberOfLocations === 2){
              feeValue = 1.57
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.65
            }
        }
      } else if(shippingWeight > 12 && shippingWeight <= 28){
        switch(user?.inboundRegion){
          case 'west':
            if(user?.numberOfLocations === 2){
              feeValue = 1.97
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.81
            }
            break
          case 'central':
            if(user?.numberOfLocations === 2){
              feeValue = 1.97
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.81
            }
            break
          case 'east':
            if(user?.numberOfLocations === 2){
              feeValue = 1.97
            } else if(user?.numberOfLocations ===3){
              feeValue = 0.81
            }
        }
      } else if(shippingWeight > 28 && shippingWeight <= 42){
        switch(user?.inboundRegion){
          case 'west':
            if(user?.numberOfLocations === 2){
              feeValue = 2.55
            } else if(user?.numberOfLocations ===3){
              feeValue = 1.05
            }
            break
          case 'central':
            if(user?.numberOfLocations === 2){
              feeValue = 2.55
            } else if(user?.numberOfLocations ===3){
              feeValue = 1.05
            }
            break
          case 'east':
            if(user?.numberOfLocations === 2){
              feeValue = 2.55
            } else if(user?.numberOfLocations ===3){
              feeValue = 1.05
            }
        }
      } else if(shippingWeight > 42 && shippingWeight <= 50){
        switch(user?.inboundRegion){
          case 'west':
            if(user?.numberOfLocations === 2){
              feeValue = 2.99
            } else if(user?.numberOfLocations ===3){
              feeValue = 1.23
            }
            break
          case 'central':
            if(user?.numberOfLocations === 2){
              feeValue = 2.99
            } else if(user?.numberOfLocations ===3){
              feeValue = 1.23
            }
            break
          case 'east':
            if(user?.numberOfLocations === 2){
              feeValue = 2.99
            } else if(user?.numberOfLocations ===3){
              feeValue = 1.23
            }
        }
      }
    }
  }
  return feeValue
};

export const calculateForQualifiedLowInventoryLeveFee = (product) => {
  const { historicalDaysOfSupply, sizeTier, height, length, width, weight } = product

  const dimentionalWeight = (length * width * height)/139
  const shippingWeight = dimentionalWeight > weight? dimentionalWeight: weight

  let lowInventoryLevelFee = 0

  if(historicalDaysOfSupply){
    if("Small Standard Size" === sizeTier && weight <=1){
      if(historicalDaysOfSupply >= 0 && historicalDaysOfSupply < 14){
        lowInventoryLevelFee = 0.89
      } else if(historicalDaysOfSupply >= 14 && historicalDaysOfSupply < 21){
        lowInventoryLevelFee = 0.63
      } else if(historicalDaysOfSupply >= 21 && historicalDaysOfSupply < 28){
        lowInventoryLevelFee = 0.32
      }
    }else if("Large Standard Size" === sizeTier){
      if(shippingWeight <= 3){
        if(historicalDaysOfSupply >= 0 && historicalDaysOfSupply < 14){
          lowInventoryLevelFee = 0.97
        } else if(historicalDaysOfSupply >= 14 && historicalDaysOfSupply < 21){
          lowInventoryLevelFee = 0.70
        } else if(historicalDaysOfSupply >= 21 && historicalDaysOfSupply < 28){
          lowInventoryLevelFee = 0.36
        }
      } else if (shippingWeight > 3 && shippingWeight <=20){
        if(historicalDaysOfSupply >= 0 && historicalDaysOfSupply < 14){
          lowInventoryLevelFee = 1.11
        } else if(historicalDaysOfSupply >= 14 && historicalDaysOfSupply < 21){
          lowInventoryLevelFee = 0.87
        } else if(historicalDaysOfSupply >= 21 && historicalDaysOfSupply < 28){
          lowInventoryLevelFee = 0.47
        }
      }
    }
  }

  return lowInventoryLevelFee
};

export const chooseDefaultCalculationPrice = ({
    profitCalculatedBy,
    listPrice,
    buyBoxPrice,
    lowestOfferPrice,
    lowestFBAOfferPrice,
}) => {
  if (profitCalculatedBy === "buyBoxPrice") return buyBoxPrice || lowestFBAOfferPrice || listPrice;
  if (profitCalculatedBy === "lowestOfferPrice") return lowestOfferPrice || listPrice;
  if (profitCalculatedBy === "lowestFBAOfferPrice") return lowestFBAOfferPrice || buyBoxPrice || listPrice;
  return listPrice;
};

export const GetProfitEstimations = (updatedData) => {
  const {
      costPrice,
      defaultPrice,
      fee,
      fulfilmentType,
      shipBy,
      shippingRate,
      prepCenterFees,
      weight,
      fbmFee,
      lowInventoryLevelFee,
      placementServiceFee,
      onOffLowInventoryLvlFee,
      exemptedFromLowInventoryLevelFee,
      onOffFBAInboundPlacementServiceFee
  } = updatedData;

  let feeses = 0;

  if (fee) {
    if (defaultPrice > 0 && fee.feePercentage) {
      feeses += defaultPrice * (fee.feePercentage / 100);
    }

    if(fee.otherFees && fulfilmentType?.toUpperCase() === 'AMAZON' ) {
      feeses += fee.otherFees;
    }

    if (fulfilmentType === 'Amazon' || fulfilmentType === 'AMAZON') {
      const currentMonth = new Date().getMonth();
      if ([9, 10, 11].includes(currentMonth)) {
        if (fee.monthlyStorageFeeOct) {
          feeses += fee.monthlyStorageFeeOct;
        }
      } else if (fee.monthlyStorageFeeJan) {
        feeses += fee.monthlyStorageFeeJan;
      }
    }
    else{
      feeses += fbmFee??0;
    }

    if (lowInventoryLevelFee > 0 && (exemptedFromLowInventoryLevelFee == "No" || !exemptedFromLowInventoryLevelFee) && onOffLowInventoryLvlFee){
      feeses += lowInventoryLevelFee
    }

    feeses = round(feeses, 2);
  }

  if (placementServiceFee && onOffFBAInboundPlacementServiceFee) {
    feeses += placementServiceFee
  }

  let totalCosts = 0;
  if (costPrice > 0) {
    totalCosts += costPrice;
  }

  if (fulfilmentType === 'Amazon' || fulfilmentType === 'AMAZON') {
    if (shipBy === 'byItem') {
      if (shippingRate > 0) {
        totalCosts += shippingRate;
      }
    } else if (shipBy === 'byWeight') {
      if (shippingRate > 0 && weight > 0) {
        totalCosts += (shippingRate * weight);
      }
    }
  }

  prepCenterFees ? totalCosts += prepCenterFees : totalCosts;
  const calculation = defaultPrice - feeses - totalCosts;
  const profit = Math.round((calculation+Number.EPSILON)*100)/100 || 0;
  let retObj = { profit };
  if (profit !== null) {
    const profitPercentage = defaultPrice > 0 ? Math.round((((profit / defaultPrice) * 100)+Number.EPSILON)*100)/100 : 0;
    const roiPercentage = costPrice > 0 ? Math.round((((profit / costPrice) * 100)+Number.EPSILON)*100)/100 : 0;
    retObj = {...retObj, profitPercentage, roiPercentage }
  }

  return retObj;
};
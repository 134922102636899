import React from 'react'

import './form.scss'

function Form ({ fields, submitText, onSubmit, isSending }) {
  const buttonClass = `submit-button${isSending ? " is-sending" : ""}`
  return (
    <form className="form" onSubmit={onSubmit}>
      {
        fields.map(({ label, errorMessage, fieldProps, isCentered = false, isMuted = false, isSmall = false }, i) => {
          const centered = isCentered ? " is-centered" : ""
          const muted = isMuted ? " is-muted" : ""
          const small = isSmall ? " is-small" : ""
          const className = `form-field${centered}${muted}${small}`

          return fieldProps.type === "checkbox"
            ? <div className={className + " is-regular"} key={i}>
                <label>
                  <input {...fieldProps} /> {label}
                </label>       
              </div>
            : <div className={className} key={i}>
                <label>{label}</label>
                <input {...fieldProps} />
                { errorMessage && <p className="error-message">{errorMessage}</p> }
              </div>
        })
      }
      <button className={buttonClass} type="submit" disabled={isSending}>
        {submitText}
      </button>
    </form>
  )
}

export default Form
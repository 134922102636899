import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

import ParentItem from './parent-items'
import Spinner from '../spinner'
import ConfirmResolve from '../unresolved-products/resolve-modal'

import './packing.scss'

const Packing = ({ match, item, setItem, openModal, setOpenModal }) => {
    const [childInfo, setChildInfo] = useState({ isResolved: false })
    const [parents, setParents] = useState([])

    //For Spinner
    const [isLoading, setIsLoading] = useState(false)

    //For Resolve modal
    const [rows, setRows] = useState([])

    const childId = match.params.childId
    const history = useHistory()

    const thumbnail = childInfo.imageUrl
    ? `https://images-na.ssl-images-amazon.com/images/I/${childInfo.imageUrl.split(".")[0]}._AC_US96_.jpg`
    : "/assets/images/no-image-available.svg"

    useEffect(() => {
        setIsLoading(true)
        axios.get(`/api/v1/child-inventory/child-info/${childId}`)
             .then(({ data }) => {
                 setChildInfo(data)
                 setItem(data)
                 setIsLoading(false)
             })
        axios.get(`/api/v1/child-inventory/parent/${childId}?populateChild=1`)
             .then(({ data }) => {
                 setParents(data)
             })
    }, [])

    useEffect(() => {
        const fetchData = () => {
            axios.get(`/api/v1/child-inventory/child-info/${childId}`)
                 .then(({ data }) => {
                    setChildInfo(data)
                    setItem(data)
                    setIsLoading(false)
                 })
            axios.get(`/api/v1/child-inventory/parent/${childId}?populateChild=1`)
                 .then(({ data }) => {
                    setParents(data)
                 })
        }
        window.addEventListener('focus', fetchData)

        return () => window.removeEventListener('focus', fetchData)
    }, [])

    useEffect(() => {
        const { isResolved } = childInfo
        if (isResolved) {
            history.push('/cart')
        }
    }, [childInfo])

    return (
        <div className="packing">
            {isLoading && <Spinner isCentered={true} type="overlay" />}
            <ConfirmResolve open={openModal} setOpen={setOpenModal} item={item} setItem={setItem} setRows={setRows} setIsLoading={setIsLoading} />

            {/* Child Product Info */}
            <div className="child-product">
                <img src={thumbnail} alt={childInfo.title}/>
                <div className="child-product-info">
                    <p className="title">{childInfo.title}</p>
                    <div className="child-product-asin-sku">
                        <span>{childInfo.asin}</span>
                        <span>{childInfo.sellerSku}</span>
                    </div>
                </div>
            </div>
            {/* Child Product Inventory Info */}
            <div className="child-inventory-info">
                <div className="prop-group">
                    <p className="label">local inventory:</p>
                    <p className="value">{childInfo.localInventory}</p>
                </div>
                <div className="prop-group">
                    <p className="label">buy qty:</p>
                    <p className="value">{childInfo.decidedBuyQuantity}</p>
                </div>
                <div className="prop-group">
                    <p className="label">Sellable:</p>
                    <p className="value">Yes</p>
                </div>
            </div>

            <div className="parent-items">
                { parents && parents.length > 0 && parents.map((parent, index) => (
                    <ParentItem parent={parent} key={index} setItem={setItem} setIsLoading={setIsLoading} childInfo={childInfo} setChildInfo={setChildInfo} childId={childId} setParents={setParents} />
                )) }
            </div>

        </div>
    )
}

export default Packing

import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import Card from '../card'
import Form from '../form'
import Toast from '../toast'

import checkPassword from '../../utilities/js/password-checker'

const UpdatePassword = () => {
    const history = useHistory()
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [passwordError, setPasswordError] = useState('')
    const [confirmPasswordError, setConfirmPasswordError] = useState('')

    const [isSending, setIsSending] = useState(false)

    const [toastMessage, setToastMessage] = useState('')
    const [toastType, setToastType] = useState('error')
    const [isOpen, setIsOpen] = useState(false)

    const resetPasswordToken = new URLSearchParams(window.location.search).get('resetPasswordToken')

    const onSubmit = e => {
        e.preventDefault()
        if (!password) {
            setPasswordError('This field is required.')
        }
        if (!confirmPassword) {
            setConfirmPasswordError('This field is required.')
        }

        if (!passwordError && !confirmPasswordError && password && confirmPassword) {
            setIsSending(true)
            axios.post('/api/v1/update-password', { resetPasswordToken, password })
                 .then(res => {
                     setIsSending(false)
                     setToastMessage('Password updated successfully. Redirecting to the login page.')
                     setToastType('success')
                     setIsOpen(true)
                     setTimeout(() => {
                        history.push('/auth/login')
                     }, 3000)
                 })
                 .catch(err => {
                     setIsSending(false)
                     setToastMessage('Invalid or link has expired.')
                     setToastType('error')
                     setIsOpen(true)
                 })
        }
    }

    const handlePasswordChange = e => {
        setPassword(e.target.value)
        
        if (e.target.value.length < 8) {
            setPasswordError('Password must be atleast 8 characters.')
        } else {
            if (checkPassword(e.target.value)) {
                setPasswordError('')
            } else {
                setPasswordError('Password must contain atleast one uppercase, lowercase, number and special character.')
            }
        }

        if (confirmPassword && e.target.value !== confirmPassword) {
            setConfirmPasswordError('Please make sure your passwords match.')
        } else {
            setConfirmPasswordError('')
        }

    }

    const handleConfirmPasswordChange = e => {
        setConfirmPassword(e.target.value)

        if (e.target.value !== password) {
            setConfirmPasswordError('Please make sure your passwords match.')
        } else {
            setConfirmPasswordError('')
        }
    }


    const fields = [
        {
          label: "New Password",
          fieldProps: {
            type: "password",
            value: password, name: "password",
            onInput: handlePasswordChange
          },
          errorMessage: passwordError
        },
        {
          label: "Confirm New Password",
          fieldProps: {
            type: "password",
            value: confirmPassword, name: "password",
            onInput: handleConfirmPasswordChange
          },
          errorMessage: confirmPasswordError
        },
      ]

    return (
        <>
            <Card>
                <div className="title">Update Password</div>
                <Form fields={fields} submitText="Update Password" onSubmit={onSubmit} isSending={isSending} />
                
            </Card>
            <Toast isOpen={isOpen} handleClose={() => setIsOpen(false)} type={toastType}>
                {toastMessage}
            </Toast>
            
        </>
    )
}

export default UpdatePassword

import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { Dashboard, List, Delete, AssignmentTurnedIn } from '@material-ui/icons'

import './bottom-nav.scss'

const BottomNav = ({ isLongPress, setIsLongPress, pathname, deleteFunction, selectedDelete, setSelectedDelete }) => {
  const handleDelete = e => {
    e.preventDefault()
    if (selectedDelete !== null) {
      deleteFunction(selectedDelete)
    }
    setSelectedDelete(null)
    setIsLongPress(false)
  }
  const match = useRouteMatch('/shopped-list/:sellerSku/:ext') || useRouteMatch('/shopping-list/:sellerSku/:ext') || useRouteMatch('/shopped-list/:sellerSku') || useRouteMatch('/shopping-list/:sellerSku')

  const { path: pathInventory } = ( useRouteMatch('/inventory/:productId') ) ?? {}
  const selectedInventory = localStorage.getItem('selectedInventory')

  return (
    <nav className="bottom-nav">
      { match && match.isExact && isLongPress ? (
      <NavLink to='/' onClick={handleDelete}>
        <Delete /> Delete
      </NavLink>
      ) : (
      <>
        <NavLink to="/dashboard" activeClassName="active">
          <Dashboard /> Dashboard
        </NavLink>
        <NavLink to={selectedInventory ? pathInventory ? `/inventory` : `/inventory/${selectedInventory}` : `/inventory` } activeClassName="active">
          <AssignmentTurnedIn /> Inventory
        </NavLink>
        <NavLink to="/shopping-list" activeClassName="active">
          <List /> Shopping List
        </NavLink>
      </>
      ) }

    </nav>
  )
}

export default BottomNav
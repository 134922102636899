import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Card from '../card'
import Form from '../form'
import Toast from '../toast'

const ForgotPassword = () => {
    const [email, setEmail] = useState('')
    const [isSending, setIsSending] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [toastType, setToastType] = useState('error')

    const fields = [
        { 
          label: "Email Address",
          fieldProps: {
            type: "email", value: email,
            name: "email", onInput: e => setEmail(e.target.value)
          }
        }
      ]

    const onSubmit = e => {
        e.preventDefault()
        if (email) {
            setIsSending(true)
            axios.post('/api/v1/forgot-password-pwa', { email })
            .then(res => {
                setIsSending(false)
                setToastMessage(`Reset password link was sent to ${email}.`)
                setToastType('success')
                setIsOpen(true)
            })
            .catch(err => {
                setIsSending(false)
                setToastMessage(`User with email address ${email} was not found.`)
                setToastType('error')
                setIsOpen(true)
            })
        }
    }

    return (
        <>
            <Card>
                <div className="title">Reset Password</div>
                <Form fields={fields} submitText="Reset Password" onSubmit={onSubmit} isSending={isSending} />
                <p> <Link to='/auth/login' className="link-orange">Login</Link> to my account</p>
            </Card>
            <Toast isOpen={isOpen} handleClose={() => setIsOpen(false)} type={toastType}>
                {toastMessage}
            </Toast>
        </>
    )
}

export default ForgotPassword

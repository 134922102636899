import React from 'react'

const Dashboard = () => {
  return (
    <div className="coming-soon">
      <div className="coming-soon-text">
        <h1>Content coming soon</h1>
        <p>
          <strong>Dashboard Page</strong>
        </p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor lectus duis suspendisse massa, tempor dictum ut sed. Nunc maecenas quisque integer tristique iaculis.</p>
      </div>
      <div className="coming-soon-image">
        <img src="/assets/images/coming-soon.svg" alt="Coming Soon Image"/>
      </div>
    </div>
  )
}

export default Dashboard
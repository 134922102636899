import React, { useState, useEffect, useRef } from 'react';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { red } from '@material-ui/core/colors';
import axios from 'axios'
import { Link } from 'react-router-dom'
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized'

import InventoryItem from './inventory-list-item'
import Spinner from '../spinner'

import './inventory.scss'

const Inventory = ({ skip, inventory, setInventory }) => {
  const cache = useRef(new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 150,
  }))
  const savedSearch = typeof window !== 'undefined' ? localStorage.getItem('inventorySearch') : null

  const [search, setSearch] = useState(savedSearch ? savedSearch : '')
  const [sorter, setSorter] = useState("A to Z")

  useEffect(() => {
    setInventory(old => { return { products: old.products.sort((a, b) => {
      if (sorter === 'A to Z') {
        if (a.title < b.title) { return -1 }
        if (a.title > b.title) { return 1 }
      } else {
        if (b.title < a.title) { return -1 }
        if (b.title > a.title) { return 1 }
      }
    }) } })
  }, [skip])

  useEffect(() => {
    localStorage.setItem('inventorySearch', search)
  }, [search])

  useEffect(() => {
    localStorage.setItem('selectedInventory', '')
  }, [])

  const handleSort = () => {
    let productsList = inventory.products;
   
    if (sorter === 'Z to A') {
      productsList.sort((a, b) => {
        if(a.title < b.title) { return -1; }
        if(a.title > b.title) { return 1; }
        return 0;
      })
     
    } else {
      productsList.sort((a, b) => {
        if(b.title < a.title) { return -1; }
        if(b.title > a.title) { return 1; }
        return 0;
      })
     
    }
    setSorter(old => old === "A to Z" ? "Z to A" : "A to Z")
    
  }

  const listFilter = (product) => {
    const keysToFilter = ["fnsku", "sellerSku", "title", "asin"]
    const lowerCaseSearchTerm = search.toLowerCase()
    const isInSearchResult = keysToFilter.some(key => {
      const lowerCaseKeyValue = product[key]?.toLowerCase()
      return lowerCaseKeyValue?.includes(lowerCaseSearchTerm)
    })
    return isInSearchResult
  }

  return (
    <div className="inventory">
      {/* Filter section */}
      <div>
        <div className="controls">
          <div className="search-container">
            <input
              type="search" className="search" onInput={e => setSearch(e.target.value)}
              value={search} placeholder="Search for Title, ASIN, FNSKU, or Seller SKU"
            />
          </div>
          <div className="sort-container">
            <div className='sort'>
              <div className='sort-by'>Sort by: <span onClick={handleSort}>{sorter}<ImportExportIcon className='sort-icon' style={{ color: red[500] }} fontSize="small" /></span></div>
              <div className='sort-result'>Result: <span>{inventory.products.filter(listFilter).length}</span></div>
            </div>
          </div>
        </div>
      </div>
      {/* End of filter section */}
      <div className='inventory-items'>
        {inventory && inventory.products.filter(listFilter).length > 0 ? (
          <div style={{width: '100%', height: '100%'}}>
            <AutoSizer>
              {({ width, height }) => (
                <List width={width} height={height} rowHeight={cache.current.rowHeight} rowCount={inventory.products.filter(listFilter).length}
                  deferredMeasurementCache={cache.current}
                  rowRenderer={({key, index, style, parent}) => {
                    const inventoryItem = inventory.products.filter(listFilter)[index]
                    const rowStyle = { ...style, backgroundColor: index % 2 ? 'unset' : 'white' }
                    return (
                      <CellMeasurer key={key} cache={cache.current} parent={parent} columnIndex={0} rowIndex={index} >
                        <Link to={`/inventory/${inventoryItem._id}`} style={rowStyle} className={`inventory-item-link`} >
                          <InventoryItem inventoryItem={inventoryItem} style={style}/>
                        </Link>
                      </CellMeasurer>
                    )
                  }}
                />
              )}
            </AutoSizer>
          </div>
        
        ): (
          <Spinner isCentered={true} />
        )}
      </div>
    </div>
  )
}

export default Inventory
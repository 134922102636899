import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'

import './accordionFlex.scss'

const NinetyDays = ({ expanded, setExpanded, product }) => {
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }
    const { buyQuantity90Days, totalOrdersLast90Days, salesLast90Days, salesAmountLast90Days, totalPendingOrdersLast90Days, afnTotalQuantity, localInventory, decidedBuyQuantity } = product

    let adjusted90BuyQty = 0;
    if(!isNaN(buyQuantity90Days))
    {
      adjusted90BuyQty = buyQuantity90Days;
      if(!isNaN(localInventory))
      {
        adjusted90BuyQty = buyQuantity90Days - localInventory;
      }
      if(!isNaN(decidedBuyQuantity))
      {
        adjusted90BuyQty = adjusted90BuyQty - decidedBuyQuantity;
      }
    }

    const expandedRows = [
        { label: 'orders', value: totalOrdersLast90Days ?? 0},
        { label: 'units', value: salesLast90Days ?? 0},
        { label: 'sale amount', value: `$${salesAmountLast90Days ?? 0}`},
        { label: 'pending orders', value: totalPendingOrdersLast90Days ?? 0},
        { label: 'inventory qty', value: afnTotalQuantity ?? 0},
        { label: 'local qty', value: localInventory ?? 0},
        { label: 'recommended buy list adj', value: adjusted90BuyQty ?? 0}
    ]

    return (
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} style={{borderBottom: '1px solid var(--replen-light-bg)'}}>
            <AccordionSummary expandIcon={<ArrowDropDown />} aria-controls="panel-content" id="panel-header" >
                <div className="accordion-summary">
                    <p className="label">90 Days</p>
                    <p className="value">{adjusted90BuyQty ?? 0}</p>
                </div>
            </AccordionSummary>
            <AccordionDetails id="panel-body">
                <div className="accordion-body">
                    {expandedRows.map((row, index) => (
                        <div className="accordion-body-row" key={index}>
                            <p className="label">{row.label}:</p>
                            <p className="value">{row.value}</p>
                        </div>
                    ))}
                </div>
            </AccordionDetails>
            
        </Accordion>
    )
}

export default NinetyDays

import React, { useState, useEffect, useRef } from 'react'
import { useQueryClient, useQuery, useMutation } from 'react-query'
import { Add, Remove, Close, ArrowDropDown } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import CurrencyInput from 'react-currency-input-field'
import axios from 'axios'

import TaxCodeInput from '../tax-code-input'
import numberToUSD from '../../utilities/js/number-to-usd'
import { useLongPress } from 'use-long-press'
import { useMediaQuery } from 'react-responsive'
import BuyListGroupSelection from '../buy-list-selection/buy-list-selection'
import DefaultBuylist from '../default-buylist'
import BuyListMergeSelection from '../buy-list-merge-selection/buy-list-merge-selection'
import Spinner from '../spinner'
import Toast from '../toast'

import './bought-qty.scss'

const BoughtQty = ({ selected, setSelected, isRefetchingEnabled, setIsRefetchingEnabled, pageFrom, isLongPress, setIsLongPress, setDeleteFunction, selectedDelete, setSelectedDelete, list, setNewCartItems, user }) => {

  const { 
    title, asin, sellerSku, costPrice, supplier, decidedBuyQuantity, isSellable, taxCode: defaultTaxCode, imageUrl,

    fulfilmentType,
    afnReservedQuantityCustOrder,
    afnReservedQuantityFcProc,
    afnReservedQuantityFcTrans,
    afnInboundWorkingQuantity,
    afnInboundShippedQuantity,
    afnInboundReceivingQuantity,
    afnResearchingQuantity,
    sellableQuantity,
    afnUnsellableQuantity,
    afnTotalQuantity,
    
    storeSection, isAlwaysBuyList, notesSection, customTitle, purchaseLink
  } = selected

  // for reserved qty
  const newAfnReservedQuantity = (afnReservedQuantityCustOrder ?? 0) + (afnReservedQuantityFcProc ?? 0) + (afnReservedQuantityFcTrans ?? 0);
  
  let newAfnTotalQuantity = 0;

  if (fulfilmentType != "Merchant") {
    newAfnTotalQuantity = (afnInboundWorkingQuantity ?? 0) + (afnInboundShippedQuantity ?? 0) + (afnInboundReceivingQuantity ?? 0) + newAfnReservedQuantity + (afnResearchingQuantity ?? 0);
  }
  
  newAfnTotalQuantity += (sellableQuantity ?? 0);

  const isMobile = useMediaQuery( { query: '(max-width: 600px)'})
  const isTablet = useMediaQuery( {query: '(min-width: 720px) and (max-width: 1024px) and (orientation: portrait)' } )

  const  [amazonUrl, setAmazonUrl] = useState(null)
  const [showBuyList, setShowBuyList] = useState(false)

  const [expandedParent, setExpandedParent] = useState([])
  const [fetchingParents, setFetchingParents] = useState(false)
  const [parentItems, setParentItems] = useState([])
  const [childItems, setChildItems] = useState([])
  const [productChildId, setProductChildId] = useState('')

  const [isToastOpen, setIsToastOpen] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [toastType, setToastType] = useState('success')

  const [showBuyListMerge, setShowBuyListMerge] = useState(false)
  const [buyListArray, setBuyListArray] = useState([])

  const [buyQty, setBuyQty] = useState(decidedBuyQuantity)

  useEffect(() => {
    checkUserMarketPlace()
  }, [amazonUrl])

  useEffect(() => {
    if (isMobile && isTablet) {
      setIsLongPress(false)
      setSelectedDelete(null)
    }
  }, [])

  useEffect(() => {
    setParentItems([])
    setFetchingParents(true)
    setExpandedParent([])
    axios.get(`/api/v1/child-inventory/productInfo/${asin}?sellerSku=${sellerSku}`)
      .then(({ data }) => {
        document.querySelector('.content').scrollTo(0,0)
        const [childProduct] = data.products
        const childId = childProduct.childId
        setProductChildId(childId)
        if(childId){
          axios.get(`/api/v1/child-inventory/parent/${childId}`)
          .then(({ data }) => {
            setParentItems(data)
            setFetchingParents(false)
          })
          .catch(err => console.error(err))
        }
      })
      .catch(err => console.error(err))
  }, [selected])

  useEffect(() => {
    parentItems.map((parentItem) => {
      if (parentItem.childItems.length > 0) {
        parentItem.childItems.map(({ child }) => {
          axios.get(`/api/v1/child-inventory/child-info/${child}`)
               .then(({ data }) => setChildItems(old => ([...old, data])) )
               .catch(err => console.log(err))
        })
      }
    })
  }, [parentItems])
  
  const initialFormState = { 
    qty: 0, 
    expiryDate: "", 
    taxCode: defaultTaxCode ?? "A_GEN_TAX",
    condition: "New",
    costPrice: costPrice ?? ""
  }
  const [ form, updateForm ] = useState({ ...initialFormState })
  const [ isLoading, setIsLoading ] = useState(false)
  const { qty, expiryDate, taxCode, condition, costPrice: formCost } = form  

  const { data } = useQuery(
    ["inventoryMovements", sellerSku], 
    getLocalInventoryMovements, 
    {
      enabled: isRefetchingEnabled,
      initialData: { inventoryMovements: [] },
      refetchInterval: 1000,
      refetchOnReconnect: false
    }
  )

  useEffect(() => {
    if (data.inventoryMovements.length === 0) {
      setIsLongPress(false)
    }
  }, [data])
  
  const qtyInputRef = useRef(null)

  const [isOpen, setIsOpen] = useState(false)

  const thumbnail = imageUrl
    ? `https://images-na.ssl-images-amazon.com/images/I/${imageUrl.split(".")[0]}._AC_US96_.jpg`
    : "/assets/images/no-image-available.svg"
  
  // Set up the mutations for optimistic updates in the Shopping List and Inventory Movements server states
  const history = useHistory()
  const queryClient = useQueryClient()
  const addToCartMutation = useMutation(addToCart, {
    retry: 3,
    onMutate: async formData => {
      const { quantity: inQuantity, pwaDateCreated, ...otherDetails } = formData
      updateForm(prevState => { return { ...initialFormState, taxCode: prevState.taxCode, expiryDate: prevState.expiryDate } })
      setSelected(old => ({ ...old, parentBuyQty: old.parentBuyQty - inQuantity}))
      setIsRefetchingEnabled(false)
      
      await queryClient.cancelQueries("shoppingList")
      await queryClient.cancelQueries(["inventoryMovements", sellerSku])

      const newInventoryMovement = { inQuantity, pwaDateCreated, otherDetails }
      const previousValues = {
        shoppingList: queryClient.getQueryData("shoppingList"),
        inventoryMovements: queryClient.getQueryData(["inventoryMovements", sellerSku])
      }
      
      queryClient.setQueryData(["inventoryMovements", sellerSku], old => ({
        ...old,
        inventoryMovements: [ newInventoryMovement, ...old.inventoryMovements ],
      }))

      queryClient.setQueryData("shoppingList", old => {
        const productIndex = old.products.findIndex(product => product.sellerSku === sellerSku)
        const decreasedQty = old.products[productIndex].decidedBuyQuantity - inQuantity
        console.log('old', old)
        // Make the decreased qty the new qty if it is greater than 0, else just make it 0
        old.products[productIndex].decidedBuyQuantity = decreasedQty > 0 ? decreasedQty : 0
        setBuyQty(decreasedQty > 0 ? decreasedQty : 0)
        return old
      })
      //commented out redundant on the onSuccess section
      // if (!isAlwaysBuyList && (decidedBuyQuantity - inQuantity < 1)) {
      //   // history.push('/shopping-list')
      //   history.goBack()
      // }
      // else{
      //   setBuyQty(buyQty - inQuantity)
      //   setToastMessage(`Product was successfully moved to local inventory`)
      //   setToastType('success')
      //   setIsOpen(true)
      // }

      return previousValues
    },
    onError: (err, variables, { inventoryMovements, shoppingList }) => {
      console.error("Mutation error", err)
      console.error("Mutation variables", variables)
      queryClient.setQueryData("shoppingList", shoppingList)
      queryClient.setQueryData(["inventoryMovements", sellerSku], inventoryMovements)
      setToastType('error')
      setToastMessage('Something went wrong.')
      setIsToastOpen(true)
    },
    onSettled: () => {
      setIsRefetchingEnabled(true)

      queryClient.invalidateQueries("shoppingList")
      queryClient.invalidateQueries(["inventoryMovements", sellerSku])
    },
    onSuccess: (data) => {
      setIsLoading(false)
      setToastType('success')
      setToastMessage('Product was successfully moved to local inventory.')
      setIsToastOpen(true)
      setNewCartItems(old => +old + 1)

      if (!isAlwaysBuyList && buyQty < 1) {
        history.goBack()
      }
    }
  })

  const removeFromLocalInventoryMutation = useMutation(removeFromLocalInventory, {
    retry: 3,
    onMutate: async ({ index }) => {
      setIsRefetchingEnabled(false)

      await queryClient.cancelQueries("shoppingList")
      await queryClient.cancelQueries(["inventoryMovements", sellerSku])
      
      const previousValues = {
        shoppingList: queryClient.getQueryData("shoppingList"),
        inventoryMovements: queryClient.getQueryData(["inventoryMovements", sellerSku])
      }
      
      queryClient.setQueryData(["inventoryMovements", sellerSku], old => ({
        ...old,
        inventoryMovements: [
          ...old.inventoryMovements.filter((_, i) => i !== index)
        ],
      }))

      queryClient.setQueryData("shoppingList", old => {
        const productIndex = old.products.findIndex(product => product.sellerSku === sellerSku)
        const removedQty = data.inventoryMovements[index].inQuantity

        old.products[productIndex].parentBuyQty += removedQty
        setBuyQty(buyQty + removedQty)
        return old
      })

      return previousValues
    },
    onError: (err, variables, { inventoryMovements, shoppingList }) => {
      console.error("Mutation error", err)
      console.error("Mutation variables", variables)
      queryClient.setQueryData("shoppingList", shoppingList)
      queryClient.setQueryData(["inventoryMovements", sellerSku], inventoryMovements)
    },
    onSettled: () => {
      setIsRefetchingEnabled(true)
      
      queryClient.invalidateQueries(["inventoryMovements", sellerSku])
      queryClient.invalidateQueries("shoppingList")
    },
    onSuccess: () => {
      if (pageFrom === 'shopped-list') {
        history.replace(`/shopping-list/${sellerSku}`)
      }
      setIsLoading(false)
    }
  })

  async function getLocalInventoryMovements () {
    const response = await axios.get("/api/v1/inventory/inventory-movements", { params: { sellerSku, isSellable } })
    return response.data
  }

  async function addToLocalInventory (formData) {
    const { qty, pwaDateCreated, ...otherDetails } = formData

    return axios.post("/api/v1/inventory/addUpdate-pwa-local-inventory", {
      sellerSku: sellerSku,
      localInventory: qty,
      isDeleted: false,
      otherDetails: otherDetails,
      pwaDateCreated: pwaDateCreated,
      afnTotalQuantity: afnTotalQuantity,
      buyListId: selectedItem._id
    })
  }

  async function removeFromLocalInventory ({ index, pwaDateUpdated }) {
    const { pwaDateCreated, buyListId, ...otherDetails } = data.inventoryMovements[index]

    return axios.post("/api/v1/inventory/addUpdate-pwa-local-inventory", {
      sellerSku: sellerSku,
      isDeleted: true,
      otherDetails: otherDetails,
      pwaDateCreated,
      pwaDateUpdated,
      isSellable,
      buyListId: buyListId?._id
    })
  }

  async function addToCart (requestBody) {
    setIsLoading(true)
    return axios.post('api/v1/cart', requestBody)
    
  }
  
  const checkUserMarketPlace =()=>{
    const {marketplaceId} = user.mws || {}

    if(marketplaceId){
        switch(marketplaceId){
          case 'ATVPDKIKX0DER': //US
            setAmazonUrl('https://amazon.com')
            break
          case 'A1AM78C64UM0Y8': //Mexico
            setAmazonUrl('https://amazon.com.mx')
            break
          case 'A2EUQ1WTGCTBG2': //Canada
            setAmazonUrl('https://amazon.ca')
            break
          default: // Default to US marketplace domain for now
            setAmazonUrl('https://amazon.com')
        }
    }
  }

  const [selectedItem, setSelectedItem] = useState(undefined)

  const handleShowBuyList = event => {
    event.preventDefault() // prevent form submit default behavior
    
    addToCartMutation.mutate({ ...form, pwaDateCreated: Date.now() })
  }

  const handleAddToCart = () => {
    const { asin, sellerSku, imageUrl, title, productId, _id } = selected
    const { qty: quantity, costPrice, condition, expiryDate, taxCode } = form
    const requestBody = { asin, sellerSku, imageUrl, title, productId: productId ? productId : _id, quantity, costPrice, condition, expiryDate, taxCode, buyListId: selectedItem._id }
    addToCartMutation.mutate(requestBody)
  }

  const checkCartBuyList = (event) => {
    event.preventDefault()
    if(!selectedItem){
      setShowBuyList(true)
    }
    else{
      const { _id } = selected
      const childId = _id
      axios.get(`/api/v1/child-inventory/child-info/${childId}`)
      .then(res=>{
        const {buyListId} = res.data
        if(buyListId){
          if(buyListId._id !== selectedItem._id){
            setBuyListArray([{...buyListId,status:'Prior'}, {...selectedItem, status: 'New'}])
            setShowBuyListMerge(true)
          }
          else{
            handleAddToCart()
          }
        }
        else{
          handleAddToCart()
        }
      })
      .catch(err=>console.log('BuyListErr', err))
    }
  }

  const handleRemoveFromLocalInventory = (index) => () => {
    setIsLoading(true)
    removeFromLocalInventoryMutation.mutate({ index, pwaDateUpdated: Date.now() })
  }

  const handleDeleteRow = index => {
    setIsLoading(true)
    removeFromLocalInventoryMutation.mutate({ index, pwaDateUpdated: Date.now() })
  }

  useEffect(() => {
    setDeleteFunction(() => handleDeleteRow)
  }, [])

  const onInputChange = event => {
    let { name, value } = event.target
      
    if (name === "qty") value = Number(value)
    
    updateForm(prevState => { return { ...prevState, [name]: value } })
  }

  const incrementQty = () => {
    updateForm(prevState => { return { ...prevState, qty: Number(prevState.qty) + 1 } })
  }

  const decrementQty = () => {
    const oneDecreasedQty = Number(qty) - 1
    const newQty = oneDecreasedQty < 0 ? "" : oneDecreasedQty

    updateForm(prevState => { return { ...prevState, qty: newQty } })
  }

  const handleFocus = event => {
    event.target.scrollIntoView({ behavior: "smooth", block: "nearest" })
    event.target.select()
  }
  const handleCostChange = newCost => updateForm(prevState => { return { ...prevState, costPrice: newCost ? newCost : "" } })
  const handleTaxCodeChange = newTaxCode => updateForm(prevState => { return { ...prevState, taxCode: newTaxCode } })
  const handleQtyBackspace = event => {
    // We are getting the raw value of the input (which is a string) to get the actual length
    if (event.key === "Backspace" && qtyInputRef.current.value.length === 1) {
      updateForm(prevState => { return { ...prevState, qty: "" } })
    }
  }

  // Unselect the shopping list item after unmounting the component
  useEffect(() => () => setSelected(null), [])

  // Unselect transaction when view changed from mobile to desktop
  useEffect(() => {
    if (!isMobile || !isTablet) {
      setSelectedDelete(null)
      setIsLongPress(false)
    }
  }, [isMobile, isTablet])

  const enableDelete = e => {
    e.preventDefault()
    const timestamp = e.target.closest('.table-row').getAttribute("data-pwadatecreated")
    setSelectedDelete(e.target.closest('.table-row').id)
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100)
    }
    if (isMobile || isTablet) {
      if (isWithinTheDay(+timestamp) && (isSellable == undefined || isSellable != false)) {
        setIsLongPress(true)
      }
    } else {
      setIsLongPress(false)
      setSelectedDelete(null)
    }
  }

  const longPress = useLongPress(enableDelete, {
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: false,
  })
  
  const isWithinTheDay = (timestamp) => {
    const dateNow = new Date()
    const dateStartOfDay = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), 0, 0, 0).getTime()
    const dateEndOfDay = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), 23, 59, 59).getTime()

    if (timestamp >= dateStartOfDay && timestamp <= dateEndOfDay) {
      return true
    } else {
      return false
    }
  }

  const handleOnClickRow = (index, timestamp) => {
    if (isLongPress && isWithinTheDay(+timestamp)) {
      setSelectedDelete(index)
    }
  }

  const handleExpandParent = e => {
    const parentRow = e.target.closest('.table-body-row')
    const parentId = parentRow.getAttribute('data-parentid')
    setExpandedParent(old => {
      if (old.includes(parentId)) {
        return old.filter(o => o !== parentId)
      } else {
        return [...old, parentId]
      }
    })
  }

  const clickOnLink = (childItem) => {
    const { parentBuyQty, sellerSku } = childItem

    const newSelected = list.filter(li => li.sellerSku == sellerSku)[0]

    if (newSelected) {
      setSelected(newSelected)
    }
    if (parentBuyQty > 0) {
      history.push(`/shopping-list/${sellerSku}`)
    } else {
      history.push(`/shopped-list/${sellerSku}`)
    }
  }

  // const handleAddToLocalInventory = () => {
  //   addToLocalInventoryMutation.mutate({ ...form, pwaDateCreated: Date.now() })
  //   // setSelectedItem(undefined)
  // }

  return (
    <div className="bought-qty">
    { <BuyListGroupSelection
      onBuyList={handleAddToCart}
      showBuyList={showBuyList}
      setShowBuyList={setShowBuyList}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      user={user}
      />}
      <BuyListMergeSelection
        showBuyListMerge={showBuyListMerge}
        setShowBuyListMerge={setShowBuyListMerge}
        buyListArray={buyListArray}
        handleAddToCart={handleAddToCart}
        setSelectedBuyList={setSelectedItem}
        user={user}
        />
      {isLoading ? <Spinner isCentered={true} type='overlay'/> : null}
      {/* <Spinner isCentered={true} type='overlay'/> */}
      <div className="product-details">
        <img className="thumbnail" src={thumbnail} />
        <div className="links">
          <a href={`${amazonUrl}/dp/${asin}`} target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/amazon-icon.svg" />
          </a>
          <a href={`https://keepa.com/#!product/1-${asin}`} target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/keepa-icon.svg" />
          </a>
        </div>
        <div className="title">{customTitle ?? title} {(!isSellable == undefined || isSellable == false)?"(Non Sellable)": ""}</div>
        <div className="props group-1">
          <div className="prop">
            <label>ASIN</label>
            <div>{asin}</div>
          </div>
          <div className="prop">
            <label>Cost</label>
            <div>{costPrice ? numberToUSD(costPrice) : "N/A"}</div>
          </div>
          <div className="prop">
            <label>QTY</label>
            <div>{newAfnTotalQuantity}</div>
          </div>
        </div>
        <div className="props group-2">
          <div className="prop">
            <label>Supplier</label>
            <div>{supplier ?? "N/A"}</div>
          </div>
          <div className="prop">
            <label>Store Section</label>
            <div>{storeSection ?? "N/A"}</div>
          </div>
        </div>
        <div className="props group-3">
          <div className="prop">
            <label>Notes</label>
            <div>{notesSection}</div>
          </div>
          <div className='prop'>
            <label>Buy Link</label>
            <div>{purchaseLink? purchaseLink.length? purchaseLink.map(link=>(<a key={link} href={link} target='_blank'>{link}</a>)): 'N/A': 'N/A'}</div>
          </div>
        </div>
        <div className="buy-qty">
          <label>Buy Qty</label>
          <span>{isAlwaysBuyList ? "∞" : buyQty ? buyQty : 0}</span>
        </div>
      </div>
      <form className="add-form" onSubmit={checkCartBuyList}>
        <label className="field">
          <span>Actual Qty</span>
          <div className="increments">
            <input 
              name="qty" type="number" value={qty} min={1} ref={qtyInputRef} required
              onInput={onInputChange} onKeyDown={handleQtyBackspace} onFocus={handleFocus}
            />
            <button className="minus" type="button" onClick={decrementQty}>
              <Remove fontSize="inherit" />
            </button>
            <button className="plus" type="button" onClick={incrementQty}>
              <Add fontSize="inherit" />
            </button>
          </div>
        </label>
        <label className="field">
          <span>Expiration Date</span>
          <input name="expiryDate" type="date" value={expiryDate} onInput={onInputChange} />
        </label>
        <label className="field">
          <span>Tax Code</span>
          <TaxCodeInput selectedTaxCode={taxCode} setTaxCode={handleTaxCodeChange} />
        </label>
        <label className="field">
          <span>Condition</span>
          <select name="condition" type="number" value={condition} onChange={onInputChange}>
            <option value="New">New</option>
            <option value="Used">Used</option>
          </select>
        </label>
        <label className="field">
          <span>Cost</span>
          <CurrencyInput 
            name="costPrice" 
            prefix="$" 
            value={formCost} 
            onValueChange={handleCostChange} 
            placeholder="" 
            required={!costPrice}
            onFocus={handleFocus}
          />
        </label>
        <label className='field'>
          <span>Buy List</span>
          <DefaultBuylist user={user} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
        </label>
        {
          (isSellable != undefined || isSellable == false) &&
          <div className="parent-items">
            <h1 className="section-title">Parent Items</h1>
            <table className="parent-items-table">
              <thead>
                <tr className="table-header-row">
                  <th>Title</th>
                  <th>ASIN</th>
                  <th>Buy Qty</th>
                  <th>Actual Qty</th>
                </tr>
              </thead>
              <tbody>
                {parentItems.length > 0 ? parentItems.map((parent, index) => {
                  if (expandedParent.includes(`parent${index}`)) {
                    return (
                      <React.Fragment key={index}>
                        <tr className="table-body-row" style={{backgroundColor: expandedParent.includes(`parent${index}`) ? 'white' : 'var(--replen-lighter-gray)'}} data-parentid={`parent${index}`} onClick={handleExpandParent}>
                          <td><div>{parent.title}</div></td>
                          <td><div>{parent.asin}</div></td>
                          <td><div>{(parent.decidedBuyQuantity ?? 0)}</div></td>
                          <td>
                            <div>
                              <span>{ (parent.childItems.find(ch => ch.child == productChildId)?.quantity ?? 0) * (parent.decidedBuyQuantity ?? 0) }</span>
                              <button type="button" className="expand-button"><span className="rotate">▼</span></button>
                            </div>
                          </td>
                        </tr>
                        { parent.childItems.length > 0 && parent.childItems.map((ch, ind) => {
                          const isNotALink = ch.child == productChildId
                          const childItem = childItems.filter(chi => chi.childId === ch.child)[0]
                          return ( 
                            <tr className="table-body-row child" style={{backgroundColor: expandedParent.includes(`parent${index}`) ? 'white' : 'var(--replen-lighter-gray)'}} key={ind}>
                              <td><div><span className={ isNotALink ? 'normal-bullet' : 'link-bullet'}>•</span> <span className={ isNotALink ? 'normal-title' : 'link-title'} onClick={() => !isNotALink && clickOnLink(childItem)}>{childItem?.title}</span></div></td>
                              <td><div></div></td>
                              <td><div>{ ch.quantity * (parent.decidedBuyQuantity ?? 0) }</div></td>
                              <td><div></div></td>
                            </tr>
                          )
                        }) }
                      </React.Fragment>
                    )
                  } else {
                    return (
                        <tr className="table-body-row" style={{backgroundColor: expandedParent.includes(`parent${index}`) ? 'white' : 'var(--replen-lighter-gray)'}} data-parentid={`parent${index}`} onClick={handleExpandParent} key={index}>
                          <td><div>{parent.title}</div></td>
                          <td><div>{parent.asin}</div></td>
                          <td><div>{(parent.decidedBuyQuantity ?? 0)}</div></td>
                          <td>
                            <div>
                              <span>{ (parent.childItems.find(ch => ch.child == productChildId)?.quantity ?? 0) * (parent.decidedBuyQuantity ?? 0) }</span>
                              <button type="button" className="expand-button"><span>▼</span></button>
                            </div>
                          </td>
                        </tr>
                    )
                  }
                }) : fetchingParents ? (
                  <tr className="table-body-row" style={{backgroundColor: 'var(--replen-lighter-gray)'}} onClick={handleExpandParent}>
                    <td><div>Fetching parent items</div></td>
                    <td><div></div></td>
                    <td><div></div></td>
                    <td><div></div></td>
                  </tr>
                ) : (
                  <tr className="table-body-row" style={{backgroundColor: 'var(--replen-lighter-gray)'}} onClick={handleExpandParent}>
                    <td><div>This item does not have a parent item.</div></td>
                    <td><div></div></td>
                    <td><div></div></td>
                    <td><div></div></td>
                  </tr>

                )}
              </tbody>
            </table>
          </div>
        }
        <button className="submit" type="submit" disabled={isLoading}>
          {
            isLoading?"Adding...":"Add to Local Inventory"
          }
        </button>
      </form>
      {
        data?.inventoryMovements?.length > 0 &&
        <div className="added-list-container">
          <Toast isOpen={isOpen} handleClose={() => setIsOpen(false)} type={toastType}>
            {toastMessage}
          </Toast>
          <table className="added-list">
            <thead>
              <tr>
                <th>Date</th>
                <th>QTY</th>
                <th>Exp. Date</th>
                <th>Tax Code</th>
                <th>Cost</th>
                <th>Buy List</th>
                { !isMobile && !isTablet && <th></th> }
              </tr>
            </thead>
            <tbody>
              {
                data.inventoryMovements.map(({ inQuantity, otherDetails, createdAt, pwaDateCreated, buyListId }, i) => {
                  const { expiryDate, taxCode, condition, costPrice } = otherDetails
                  return (
                    <tr key={i} {...longPress} onClick={() => handleOnClickRow(i, pwaDateCreated)} style={{backgroundColor: i == selectedDelete && isWithinTheDay(+pwaDateCreated) && (isSellable == undefined || isSellable != false) ? (isMobile || isTablet)  ? '#F93402' : 'unset' : 'unset', color: i == selectedDelete && isWithinTheDay(+pwaDateCreated) ? (isMobile || isTablet) && (isSellable == undefined || isSellable != false)  ? 'white' : 'unset' : 'unset'}} id={i} className="table-row" data-pwadatecreated={pwaDateCreated}>
                      <td className="table-data" >
                        <div>{(new Date(pwaDateCreated ? +pwaDateCreated : createdAt)).toLocaleDateString()}</div>
                        <div>{(new Date(pwaDateCreated ? +pwaDateCreated : createdAt)).toLocaleTimeString()}</div>
                      </td>
                      <td className="table-data" >{inQuantity} <span className={condition === 'New' ? 'superscript-badge superscript-badge-new' : 'superscript-badge superscript-badge-old'}>{condition}</span></td>
                      <td className="table-data" >
                        {
                          expiryDate
                          ? DateTime.fromISO(expiryDate).toLocaleString() 
                          : <span className="not-set">Not set</span>
                        }
                      </td>
                      <td className="table-data">{taxCode ? taxCode : <span className="not-set">Not set</span>}</td>
                      <td className="table-data">{costPrice ? numberToUSD(costPrice) : <span className="not-set">Not set</span>}</td>
                      <td className="table-data">{buyListId?.name ? buyListId.name : <span className="not-set">Not set</span>}</td>
                      { !isMobile && !isTablet && (
                        <td>
                          {
                            (isSellable == undefined || isSellable != false) &&
                            <button className="remove" onClick={handleRemoveFromLocalInventory(i)} style={{opacity: isWithinTheDay(+pwaDateCreated) ? 'unset' : 0, pointerEvents:  isWithinTheDay(+pwaDateCreated) ? 'unset' : 'none', cursor: 'pointer'}}>
                              <Close />
                            </button>
                          }
                        </td>
                      )}
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      }
      <Toast isOpen={isToastOpen} handleClose={() => setIsToastOpen(false)} type={toastType}>
        {toastMessage}
      </Toast>
    </div>
  )
}

export default BoughtQty
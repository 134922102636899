import React, {useState, useEffect} from 'react'
import './row-edit.scss'
import axios from 'axios';

const StoreSection = ({label = '', value = '', product, inventory, setInventory, setOpenToast, setToastMsg, fetching}) => {
    const [storeSection, setStoreSection] = useState(value);

    useEffect(()=> {
        if(product.storeSection !== undefined && product.StoreSection !== null){
            setStoreSection(product.storeSection)
        }
    },[fetching])

    const onChange = (e) => {
        setStoreSection(e.target.value)
    }

    const onBlur = e => {
        e.preventDefault()
        if(product.storeSection != storeSection){
            handleChangeStoreSection(product.sellerSku, storeSection)
        }
    }

    const handleChangeStoreSection = async(sellerSku, storeSection) => {
        const newInventory = inventory.products.filter(p => p._id !== product._id)
        const {data} = await axios.post("api/v1/inventory/set-store-section", null, {params: {sellerSku, storeSection}})
        setInventory({products: [...newInventory, {...product, ...data}]})
        setToastMsg('You have successfully set store section.')
        setOpenToast(true)
    }

    return(
        <div className='row-edit-container'>
            <p className='label'>{label}</p>
            <input className='value input left' value={storeSection} onChange={onChange} onClick={e => e.target.select()} onBlur={onBlur}/>
        </div>
    )
}

export default StoreSection
import React, { useEffect, useState } from 'react'
import axios from 'axios'

import './update-email.scss'

const UpdateEmail = () => {
    const [updateResponse, setUpdateResponse] = useState({title: 'Updating email address.', message: 'Updating email address is ongoing. Please wait a second.' })
    const updateEmailToken = new URLSearchParams(window.location.search).get('updateEmailToken')

    useEffect(() => {
        axios.get(`/api/v1/update-email/${updateEmailToken}`).then(({ data }) => setUpdateResponse(data))
    }, [])

    return (
        <div className="update-email">
            <h1 className="title" >{updateResponse.title}</h1>
            <p className="message" >{updateResponse.message}</p>
        </div>
    )
}

export default UpdateEmail

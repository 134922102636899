import React, { useState, useEffect } from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'
import axios from 'axios'

import './accordionGrid.scss'

const Quantities = ({ expanded, setExpanded, product, setProduct, fetching, productCopy, setOpenToast, setToastMsg, setInventory, inventory }) => {
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }
    const {
        afnTotalQuantity,
        localInventory,
        decidedBuyQuantity,
        afnInboundWorkingQuantity,
        afnInboundShippedQuantity,
        afnInboundReceivingQuantity,
        // afnReservedQuantity,
        afnResearchingQuantity,
        sellableQuantity,
        afnUnsellableQuantity,
        sellerSku,
        _id: productId,
        afnReservedQuantityCustOrder,
        afnReservedQuantityFcProc,
        afnReservedQuantityFcTrans,
        newAfnReservedQuantity,
        newAfnTotalQuantity,
    } = product

    const [buyQty, setBuyQty] = useState(0)

    const expandedRows = [
        { label: 'inbound working', value: afnInboundWorkingQuantity ?? 0 },
        { label: 'inbound shipped', value: afnInboundShippedQuantity ?? 0 },
        { label: 'inbound received', value: afnInboundReceivingQuantity ?? 0 },
        { label: 'reserved', value: newAfnReservedQuantity ?? 0 },
        { label: 'customer order', value: afnReservedQuantityCustOrder ?? 0, sub: true },
        { label: 'fc processing', value: afnReservedQuantityFcProc ?? 0, sub: true },
        { label: 'fc transfers', value: afnReservedQuantityFcTrans ?? 0, sub: true },
        { label: 'researching', value: afnResearchingQuantity ?? 0 },
        { label: 'sellable', value: sellableQuantity ?? 0 },
        { label: 'total', value: newAfnTotalQuantity ?? 0 },
        { label: 'unsellable', value: afnUnsellableQuantity ?? 0 },
    ]

    useEffect(() => {
        if (decidedBuyQuantity !== undefined && decidedBuyQuantity !== null) {
            setBuyQty(decidedBuyQuantity)
        }
    }, [fetching])

    const handleChangeBuyQty = e => {
        e.preventDefault()
        if (product.decidedBuyQuantity != buyQty) {
            setDecideBuyQuantity(sellerSku, buyQty)
        }
    }

    const setDecideBuyQuantity = async (sku, decidedBuyQty) => {
        const filteredInventory = inventory.products.filter(item => item._id !== product._id)
        const { data } = await axios.post('/api/v1/inventory/set-decide-buy-quantity', null, { params: { sellerSku: sku, decidedBuyQuantity: decidedBuyQty === '' ? 0 : decidedBuyQty } })
        setInventory({products: [...filteredInventory, {...product, ...data}]})
        setProduct(old => ({ ...old, ...data }))
        setToastMsg('You have successfully set buy quantity.')
        setOpenToast(true)
    }

    return (
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{borderBottom: '1px solid var(--replen-light-bg)'}}>
            <AccordionSummary expandIcon={<ArrowDropDown />} aria-controls="panel-content" id="panel-header" >
                <div className="accordion-summary">
                    <div>
                        <p className="label">qty</p>
                        <p className="value">{newAfnTotalQuantity ?? 0}</p>
                    </div>
                    <div>
                        <p className="label" style={{textAlign: 'center'}}>local inv</p>
                        <p className="value" style={{textAlign: 'center'}}>{localInventory ?? 0}</p>
                    </div>
                    <div>
                        <p className="label" style={{textAlign: 'right'}}>buy qty</p>
                        <form onSubmit={handleChangeBuyQty} className="right">
                            <input type="number" value={buyQty} onChange={e => setBuyQty(e.target.value)} className="value input" onClick={e => e.target.select()} onBlur={handleChangeBuyQty} />
                        </form>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails id="panel-body">
                <div className="accordion-body">
                    {expandedRows.map((row, index) => (
                        <div className="accordion-body-row" key={index}>
                            <p className={row.label === 'total' ? 'label highlight' : 'label'} style={{paddingLeft: row.sub && '0.5rem'}} >{row.label}:</p>
                            <p className={row.label === 'total' ? 'value highlight' : 'value'}>{row.value}</p>
                        </div>
                    ))}
                </div>
            </AccordionDetails>
            
        </Accordion>
    )
}

export default Quantities

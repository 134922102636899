import React, { useEffect } from 'react'
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';

import ReplenLogoSVG from '../../components/replen-logo-svg'
import Login from '../../components/login'
import UpdateEmail from '../../components/update-email'
import ForgotPassword from '../../components/forgot-password'
import UpdatePassword from '../../components/update-password'

import './logged-out.scss'

const LoggedOutContainer = ({ user, handleLoginSuccess }) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const location = useLocation()

  useEffect(() => {
    if (pathname !== '/auth/update-email') {
      if (user) history.push('/shopping-list')
    } 
    
  }, [user])

  // Redirect to /auth/login on mount
  useEffect(() => {
    if (pathname !== '/auth/update-email') {
      if (user) {
        history.push('/')
      } else {
        const authRoutes = ['/auth/login', '/auth/forgot-password', '/auth/update-password']
        if (!authRoutes.includes(location.pathname)) {
          history.push("/auth/login")
        } 
      }
    }

  }, [])

  return (
    pathname === '/auth/update-email' ? (
      <div className="logged-out">
        <ReplenLogoSVG />
        <Switch>
          <Route 
          exact path="/auth/update-email" 
          render={p => <UpdateEmail {...p} />} 
          />
        </Switch>
      </div>
    ) : user ? (
      <Redirect to="/dashboard" />
    ) : (
      <div className="logged-out">
      <ReplenLogoSVG />
      <Switch>
        <Route 
          exact path="/auth/login" 
          render={p => <Login {...p} handleLoginSuccess={handleLoginSuccess} />} 
        />
        <Route 
          exact path="/auth/forgot-password" 
          render={p => <ForgotPassword {...p} />} 
        />
        <Route 
          exact path="/auth/update-password" 
          render={p => <UpdatePassword {...p} />} 
        />
        </Switch>
    </div>
    )

  ) 
}


export default LoggedOutContainer
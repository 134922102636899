import React, { useState, useEffect } from 'react';
import {Dialog, DialogTitle, DialogActions, Button, 
    DialogContent, List, ListItem, ListItemIcon, ListItemText, 
    Divider, Box, InputAdornment, TextField, IconButton} from '@material-ui/core'
import {List as ListIcon, Search, Add} from '@material-ui/icons'
import {green} from '@material-ui/core/colors'
import './buy-list-selection.scss'
import axios from 'axios';

const BuyListGroupSelection = ({onBuyList, showBuyList, setShowBuyList, selectedItem, setSelectedItem, user}) => {
    const [showAdd, setShowAdd] = useState(false)
    const [buyLists, setBuyLists] = useState([])
    const [errors, setErrors] = useState('')

    useEffect(() => {
        getBuyLists()
    },[showBuyList])

    const getBuyLists = async() => {
        const response = await axios.get('/api/v1/buy-list-group')
        if(response.status === 200){
            setBuyLists(response.data)
        }
        else{
            setErrors('An error has occured while getting Buy lLists')
        }
        
    }

    const handleSearchBuyList = async(filter) => {
        const params = new URLSearchParams()
        params.append('filter', filter)
        const response = await axios.get(`/api/v1/buy-list-group?${params.toString()}`)

        if(response.status === 200){
            setBuyLists(response.data)
        }
        else{
            setErrors('An error has occured while getting Buy Lists')
        }
    }

    const handleAddBuyList = async(buyList) => {
        const filters = {isArchive: false, field: 'name', value: buyList }
        const response = await axios.post('/api/v1/buy-list-group', {filters})

        if(response.status === 200){
            setBuyLists([...buyLists, response.data.buyList])
            setAddText('')
        }
        else{
            setErrors('An error has occured while getting Buy Lists')
        }
    }

    const handleKeyDown = event => {
        const {name, value} = event.target
        if(event.keyCode == 13){
            if(name === 'search-text'){
                handleSearchBuyList(value)
            }

            if(name === 'add-text'){
                handleAddBuyList(value)
            }
        }
    }

    const handleAdd = () => {
        setShowAdd(true)
    }

    const handleCancelAdd = () => {
        setShowAdd(false)
    }

    const handleCancel = () => {
        //get the default selected buy list
        const defBuylist = localStorage.getItem(`default-buylist-${user?._id}`)
        if(defBuylist){
            const jsonBuyList = JSON.parse(defBuylist)
            setSelectedItem(jsonBuyList)
        }
        setShowBuyList(false)
        // setSelectedItem(undefined)
        setErrors('')
    }

    const handleItemSelected = (item) => {
        setSelectedItem(item)
        if(item){
            setErrors('')
        }
    }

    const handleAddToLocalInv = () => {
        if(showBuyList && selectedItem){
            onBuyList()
            localStorage.setItem(`default-buylist-${user?._id}`, JSON.stringify(selectedItem))
            setShowBuyList(false)
        }
        else if(showBuyList && !selectedItem){
            setErrors("Please select a buy list!")
        }
    }

    const [searcText, setSearchText] = useState('')
    const [addText, setAddText] = useState('')

    const handleTextChanged = event => {
        const {name, value} = event.target
        if(name === 'search-text'){
            setSearchText(value)
        }
        else if (name === 'add-text'){
            setAddText(value)
        }
    }

    return <React.Fragment>
        <Dialog 
            aria-labelledby="form-dialog-title"
            open={showBuyList}
            onClose={handleCancel}
        >
            <DialogTitle id="form-dialog-title">Select BuyList</DialogTitle>
            <DialogContent>
                {showAdd?
                <Box className='input-container'>
                    <TextField
                        id="input-with-icon-textfield"
                        label="Add Buy List"
                        variant='outlined'
                        size='small'
                        name="add-text"
                        value={addText}
                        onChange={handleTextChanged}
                        onKeyDown={handleKeyDown}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={()=>handleAddBuyList(addText)}><Add className='btn-add' /></IconButton>
                            </InputAdornment>
                            ),
                        }}
                    />
                    <Divider className='divider'/>
                    <Button className='input-btn' onClick={handleCancelAdd} size='small' variant='text'>Cancel</Button>
                </Box>
                    :
                <Box className='input-container'>
                    <TextField
                        id="input-with-icon-textfield"
                        label="Search Buy List"
                        variant='outlined'
                        size='small'
                        name='search-text'
                        value={searcText}
                        onChange={handleTextChanged}
                        onKeyDown={handleKeyDown}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={()=>handleSearchBuyList(searcText)}><Search /></IconButton>
                            </InputAdornment>
                            ),
                        }}
                    />
                    <Divider className='divider'/>
                    <Button className='input-btn' onClick={handleAdd} size='small'>Create</Button>
                </Box>
                }
                <Divider className='divider'/>
                <List component="nav" aria-labelledby='main-buylist' className='buy-list' >
                    {buyLists.sort((a,b) => new Date(a.updatedAt) > new Date(b.updatedAt) ? -1 : 1).map((list, i) =>(
                        <ListItem
                            key={i}
                            dense
                            disableGutters
                            button
                            selected={selectedItem?._id === list?._id}
                            onClick={()=>handleItemSelected(list)}
                        >
                            <ListItemIcon><ListIcon/></ListItemIcon>
                            <ListItemText primary={list.name} />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            {errors && <><Divider/> <Box component="span" className='error'>{errors}</Box></>}
            <Divider className='divider'/>
            <DialogActions className='action-section'>
                <Button className='action-btn-secondary' size='small' onClick={handleCancel}>Cancel</Button>
                <Button className='action-btn-primary' size='small' onClick={handleAddToLocalInv}>Ok</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
};

export default BuyListGroupSelection;
import React from 'react'
import './row.scss'

const Row = ({ label = '', value = '' }) => {
    return (
        <div className="row-container">
            <p className="label">{label}</p>
            <p className="value">{value}</p>
        </div>
    )
}

export default Row

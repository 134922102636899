import React from "react"
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core"
import { ArrowDropDown } from "@material-ui/icons"

import './accordionFlex.scss'

const BuyLink = ({ expanded, setExpanded, product}) => {
    const { purchaseLink} = product

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }
    return (
        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} style={{borderBottom: '1px solid var(--replen-light-bg)'}}>
            <AccordionSummary expandIcon={<ArrowDropDown />} aria-controls="panel-content" id="panel-header" >
                <div className="accordion-summary">
                    <p className="label">Buy Link</p>
                    <p className="value buy-link">{(purchaseLink && purchaseLink.length)? purchaseLink.length==1? <a href={purchaseLink[0]} target="_blank">{purchaseLink[0]}</a>: `${purchaseLink.length} Links`: 'N/A'}</p>
                </div>
            </AccordionSummary>
            <AccordionDetails id="panel-body">
                <div className="accordion-body">
                    {purchaseLink? purchaseLink.map((link, index) => (
                        <div className="accordion-body-row" key={index}>
                            <a className="link" href={link} target="_blank">{link}</a>
                        </div>
                    )): 'N/A'}
                </div>
            </AccordionDetails>
        </Accordion>
    )
}

export default BuyLink
import React, { useState, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import axios from 'axios'

import Spinner from '../spinner'
import OfflinePage from '../offline-page'
import LoggedOutContainer from '../../containers/logged-out'
import LoggedInContainer from '../../containers/logged-in'

import './app.scss'

const App = () => {
  const [ user, setUser ] = useState(null)
  const [ isTokenChecked, setIsTokenChecked ] = useState(false)
  const [ isCheckUserFailed, setIsCheckUserFailed ] = useState(false)

  // Detecting iOS <=12 and 13+ https://stackoverflow.com/a/64207488/6943553
  const isDeviceAnIOS = () => {
    const iOS_1to12 = /iPad|iPhone|iPod/.test(navigator.platform)
    const iOS13_iPad = (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    const iOS1to12quirk = function() {
      var audio = new Audio() // temporary Audio object
      audio.volume = 0.5 // has no effect on iOS <= 12
      return audio.volume === 1
    }
    // `!window.MSStream` to avoid detecting IE on Windows Mobile
    const isIOS = !window.MSStream && (iOS_1to12 || iOS13_iPad || iOS1to12quirk())

    return isIOS
  }
  
  const updateViewportHeightVar = () => {
    // Get the root element (or the <html> element) style object
    const rootElementStyle = document.querySelector("html").style

    // Make use of the new Visual Viewport API to get the viewport height with a fallback to window.innerHeight
    // then assign it to the --viewport-height CSS variable in the root element. We will use this instead of 100vh
    // because some mobile browsers includes the address bar area (and also the keyboard when it's open) with 100vh.
    const height = window.visualViewport ? window.visualViewport.height : window.innerHeight
    rootElementStyle.setProperty("--viewport-height", height + "px")
  }

  const setAuthorization = (token) => {
    axios.defaults.headers.common['Authorization'] = 'JWT ' + token
  }

  const unsetAuthorization = () => {
    delete axios.defaults.headers.common['Authorization']
    localStorage.removeItem("loginToken")
  }

  const handleLogout = () => {
    localStorage.removeItem('email')
    unsetAuthorization()
    setUser(null)
  }

  const handleLoginSuccess = (loginToken, user) => {
    localStorage.setItem("loginToken", loginToken)
    localStorage.setItem('email', user.email)
    localStorage.setItem('newEmail', '')
    localStorage.setItem('password', '')
    
    setAuthorization(loginToken)
    setUser(user)
  }

  const setDefaultHeaders = () => {
    const REPLEN_API_URL = import.meta.env.VITE_PUBLIC_REPLEN_API_URL

    axios.defaults.baseURL = REPLEN_API_URL
    axios.defaults.headers.get['Content-Type'] = 'application/json'
    axios.defaults.headers.post['Content-Type'] = 'application/json'
  }

  const checkUser = async () => {
    const loginToken = localStorage.getItem("loginToken")

    try {
      if (loginToken) {
        setAuthorization(loginToken)

        const response = await axios.get('/api/v1/findUser')

        if (response.status === 200) {
          const { user } = response.data
          localStorage.setItem('email', user.email)
          
          setUser(user)
          setIsCheckUserFailed(false)
        }
      }
    }

    catch (error) {
      switch (error?.response?.status) {
        case 400: 
          unsetAuthorization()
          break
        default: 
          setIsCheckUserFailed(true)
          addOnlineListener()
      }
    }
    
    finally {
      setIsTokenChecked(true)
    }
  }

  const handleReconnect = () => {
    setIsTokenChecked(false)
    checkUser()
  }

  const addOnlineListener = () => {
    // The `once` option allows you to remove the listener after it's run one time
    window.addEventListener("online", handleReconnect, { once: true })
  }

  useEffect(() => {
    // If device is an iOS, add maximum-scale=1.0 to viewport meta tag
    // This is to prevent zooming upon focus but still allow zooming manually by pinching
    if (isDeviceAnIOS()) {
      const viewportMetaTag = document.querySelector('meta[name=viewport]')
      viewportMetaTag.content += ", maximum-scale=1.0"
    }
    
    // Set default headers upon mount
    setDefaultHeaders()

    // Check if existing token is valid in the backend
    checkUser()

    // Continously update --viewport-height CSS variable every 100 milliseconds
    void function updateLoop () {
      updateViewportHeightVar()
      setTimeout(() => updateLoop(), 100)
    }()

    // iOS has a weird behavior where the whole viewport gets shifted out of place when scrolling with the keyboard open.
    // This question (and the sandbox/video) will show you the problem: https://stackoverflow.com/q/59673557/6943553
    // For some reason, I am not able to make the solution there work so we'll be using another solution.
    // The window scroll event is uncancellable so we'll just make it scroll to the top every time it scrolls to lock it.
    window.addEventListener("scroll", () => window.scrollTo(0, 0))
  }, [])

  return (
    <div className="app">
      {
        isTokenChecked
        ? isCheckUserFailed
          ? <OfflinePage handleReconnect={handleReconnect} />
          : <Switch>
              <Route path='/auth' render={p => (
                <LoggedOutContainer 
                  {...p} user={user} handleLoginSuccess={handleLoginSuccess}
                />)}
              />
              <Route path='/' render={p => (
                <LoggedInContainer 
                  {...p} user={user} handleLogout={handleLogout} handleLoginSuccess={handleLoginSuccess}
                />)}
              />
            </Switch>
        : localStorage.loginToken
          ? <Spinner size={100} isCentered={true} />
          : null
      }
    </div>
  )
}

export default App
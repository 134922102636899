import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import './buy-list-group.scss'
import {List, ListItem, ListItemText, Button,
    Tabs, Tab, Box, ListItemSecondaryAction,
    IconButton, SwipeableDrawer, Link,
    Dialog, DialogTitle, DialogContent, DialogActions,
    TextField } from '@material-ui/core'
import { Add, PlaylistAddCheck, LibraryAddCheck, Storage } from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import axios from 'axios'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import {red} from '@material-ui/core/colors'
import Spinner from '../spinner'
import Toast from '../toast'
import { useInfiniteQuery, useQueryClient, useMutation } from 'react-query'
import BuyListItems from '../buy-list-items'

const a11Props = (index) => {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    }
}

const theme = createTheme({
    palette:{
        primary:{
            main: red[500]
        }
    }
})

const TabPanel = (props) => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <span>{children}</span>
                </Box>
            )
            }
        </div>
    )
}

const BuyListGroup = ({user}) => {
    const [showAdd, setShowAdd] = useState(false)
    const [selectedBuyList, setSelectedBuyList] = useState({})
    const [tabValue, setTabValue] = useState('buy-lists')
    const [total, setTotal] = useState(2)
    const [inputText, setInputText] = useState('')
    const [addInputText, setAddInputText] = useState('')
    const [rowsPerPage, _setRowsPerPage] = useState(20)
    const [isOpen, setIsOpen] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [toastType, setToastType] = useState('')
    const [archiveInputText, setArchiveInputText] = useState('')
    const [buyListError, setBuyListError] = useState(false)
    const [buyListErrorText, setBuyListErrorText] = useState()
    let filters ={field: 'name', value:(tabValue==='buy-lists')?inputText:archiveInputText, isArchive:(tabValue==='buy-lists')?false:true, limit: rowsPerPage, _page: 1 }
    const queryClient = useQueryClient()

    const minWidth600 = useMediaQuery('(min-width:600px)')
    const minWidth720 = useMediaQuery('(min-width:720px)')
    const maxWidth1024 = useMediaQuery('(max-width:1024px)')

    useEffect(()=>{
        let trialTotal = (data?.pages[0]?.data?.total===undefined)?10000:data?.pages[0]?.data?.total
        setTotal(Math.ceil(trialTotal/rowsPerPage))
        return () => source.cancel()
    }, [inputText, archiveInputText, tabValue])

    const getQueryKey = () => {
        return ['buyLists',`${inputText}-${archiveInputText}-${tabValue}`]
    }

    const queryKey = useMemo(()=> getQueryKey(),
    [inputText, archiveInputText, tabValue])

    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    const fetchBuyLists = ({pageParam = 1}) => {
        return axios({
            method: 'GET',
            url: '/api/v1/buy-list-group/buyLists',
            params: {filters:{...filters, _page: pageParam }},
            cancelToken: source.token
        }).catch(err => {
            if(axios.isCancel(err)) return
        })
    }

    const {
        isLoading,
        isError,
        error,
        data,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
      } = useInfiniteQuery(queryKey, fetchBuyLists, {
        getNextPageParam: (_lastPage, pages) => {
          if (pages.length < total) {
            return pages.length + 1
          } else {
            return undefined
          }
        }
      })

    const addBuyList = (newGroup) => {
        const filters = {isArchive: false, field: 'name', value: newGroup }
        return axios.post(`/api/v1/buy-list-group`, {filters})
    }

    const useAddBuyList = () => {
        return useMutation(addBuyList, {
            onSuccess: data => {
                setShowAdd(false)
                setAddInputText('')
                setToastMessage(`BuyList ${data.data.buyList.name} successfully added.`)
                setToastType('success')
                setIsOpen(true)
                queryClient.invalidateQueries(queryKey)
            }
        })
    }

    const {mutate: addNewBuyList} = useAddBuyList()

    const archiveBuyList = (params) => {
        const {id, filters} = params
        return axios.put(`/api/v1/buy-list-group/${id}`,
        {archiveBuyList: id, filters})
    }

    const useArchiveBuyList = () =>{
        return useMutation(archiveBuyList, {
            onSuccess: xdata => {
                setTotal(Math.ceil(data?.pages[0].data.total/rowsPerPage))
                queryClient.invalidateQueries(queryKey)
                setToastMessage(`BuyList ${xdata.data.buyList.name} successfully archived.`)
                setToastType('success')
                setIsOpen(true)
            }
        })
    }

    const { mutate: archiveBuyListQuery} = useArchiveBuyList()

    const restoreBuyList = (params) => {
        const {item, filters} = params
        const {_id} = item

        return axios.put(`/api/v1/buy-list-group/${_id}`,
        {restoreBuyList: _id, filters})
    }

    const useBuyListRestore = () => {
        return useMutation(restoreBuyList, {
            onSuccess: data => {
                queryClient.invalidateQueries(queryKey)
                setToastMessage(`BuyList ${data.data.buyList.name} successfully restored.`)
                setToastType('success')
                setIsOpen(true)
            }
        })
    }

    const {mutate: restoreBuyListQuery} = useBuyListRestore()

    const handleRestore = (item) => {
        const params = {item, filters}
        restoreBuyListQuery(params)
    }

    const deleteBuyList = (params) =>{
        const { item, filters } = params
        const {_id} = item
        return axios({
            url: `/api/v1/buy-list-group/${_id}`,
            method: 'DELETE',
            params: {filters}
        })
    }

    const useDeleteBuyList = () => {
        return useMutation(deleteBuyList, {
            onSuccess: data =>{
                queryClient.invalidateQueries(queryKey)
                console.log('data total', data.data.total)
                console.log('data', data)
                console.log('total',total)
                setToastMessage(`Buy List successfully deleted.`)
                setToastType('success')
                setIsOpen(true)
            },
            onError: error => {
                if(error == 'Error: Request failed with status code 403'){
                    setToastMessage(`Buy List has item(s) and cannot be deleted.`)
                    setToastType('warning')
                    setIsOpen(true)
                }
            }
        })
    }

    const {mutate: deleteBuyListQuery} = useDeleteBuyList()
    const handleDeleteBuyList = (item) => {
        const params = {item, filters}
        if(confirm(`Are you sure to delete ${item.name}?`)){
            deleteBuyListQuery(params)
        }
    }

    const handleArchiveBuyList = (id) => {
        const params = {id, filters}
        archiveBuyListQuery(params)
    }

    const handleAddBuyList = (event) => {
        console.log('addInputText', addInputText)
        if(addInputText !== ''){
            addNewBuyList(addInputText)
            setBuyListErrorText()
            setBuyListError(false)
        }
        else{
            setBuyListError(true)
            setBuyListErrorText('Buy List name is required.')
        }
    }

    const handleCancel = () => {
        setShowAdd(false)
        setBuyListError(false)
        setBuyListErrorText()
    }

    const handleCreateBuyList = () => {
        setShowAdd(true)
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue)
    }

    const observer = useRef()
    const lastBuyListElementRef = useCallback(node => {
        if(isLoading) return
        if(observer.current) observer.current.disconnect()

        observer.current = new IntersectionObserver(entries=>{
            if(entries[0].isIntersecting && hasNextPage){
                let trialTotal = (data?.pages[0]?.data?.total===undefined)?40:data?.pages[0]?.data?.total
                setTotal(Math.ceil(trialTotal/rowsPerPage))
                fetchNextPage()
            }
        })
        if(node) observer.current.observe(node)
    }, [isLoading, hasNextPage])

    const [showBuyListItem, setShowBuyListItem] = useState(false)
    const toggleDrawer = (item, open) => e=>{
        setSelectedBuyList(item)
        if(e && e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) return
        setShowBuyListItem(open)
    }

    return(
        <>
        <div className="buy-list-group-page">
            <SwipeableDrawer
                anchor='right'
                variant='temporary'
                open={showBuyListItem}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                ModalProps={{ keepMounted: true}}
                >
                    <BuyListItems buylist={selectedBuyList} toggleDrawer={toggleDrawer} user={user}/>
            </SwipeableDrawer>
                <ThemeProvider theme={theme}>
                    <Tabs value={tabValue} onChange={handleTabChange} indicatorColor='primary' textColor='primary' centered>
                        <Tab icon={<PlaylistAddCheck />} value="buy-lists" label="Buy Lists" {...a11Props('buy-lists')} wrapped />
                        <Tab icon={<Storage />} value="archive-buy-lists" label="Archived Buy Lists" {...a11Props('archive-buy-lists')} wrapped />
                    </Tabs>
                </ThemeProvider>
                <TabPanel value={tabValue} index="buy-lists">
                    <div className='buy-lists'>
                        <Toast isOpen={isOpen} handleClose={() => setIsOpen(false)} type={toastType}>
                            {toastMessage}
                        </Toast>
                        <div className="controls">
                            <input className='search' name='search-buylist' variant='' value={inputText} placeholder='Search Buy Lists' onChange={e=>setInputText(e.target.value)}/>
                            <IconButton className='btn-add' onClick={handleCreateBuyList} ><Add/>Create</IconButton>
                            <Dialog open={showAdd}>
                                <DialogTitle className='title-add' style={{textAlign:'center'}} >Create Buy List</DialogTitle>
                                <DialogContent>
                                    <TextField
                                    error={buyListError}
                                    helperText={buyListErrorText}
                                    className='search'
                                    placeholder='Enter Buy List Name'
                                    variant='outlined'
                                    size='small'
                                    value={addInputText}
                                    onChange={e=>setAddInputText(e.target.value)}/>
                                </DialogContent>
                                <DialogActions className='action-section' style={{display:'flex', justifyContent:'center'}} >
                                    <Button className='action-btn-secondary' onClick={handleCancel}>Cancel</Button>
                                    <Button className='action-btn-primary' onClick={handleAddBuyList}>Create</Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                            <div className="list-scroll">
                            <List component="nav">
                                {
                                   data && data?.pages && data?.pages.map(page=>(
                                        page?.data?.buyLists?.map((item, i)=>{
                                            if(page.data.buyLists.length === i + 1){
                                                return(
                                                    <ListItem
                                                        ref={lastBuyListElementRef}
                                                        key={i}
                                                        dense={!(minWidth720 && maxWidth1024) || !minWidth600}
                                                        button
                                                        divider
                                                        selected={item === selectedBuyList}
                                                        onClick={()=>setSelectedBuyList(item)}
                                                    >
                                                        <ListItemText className='list-text' primary={item.name} onClick={toggleDrawer(item, true)}/>
                                                        <ListItemSecondaryAction className='btn-wrapper'>
                                                            <IconButton edge="end" aria-label='delete'>
                                                                <Link className='btn-action' onClick={e=>handleArchiveBuyList(item._id)}>Archive</Link>
                                                            </IconButton>
                                                            <IconButton edge="end" aria-label='delete'>
                                                                <Link className='btn-action' onClick={()=>handleDeleteBuyList(item)}>Delete</Link>
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )
                                            }
                                            else{
                                                return(
                                                    <ListItem
                                                        key={i}
                                                        dense={!(minWidth720 && maxWidth1024) || !minWidth600}
                                                        button
                                                        divider
                                                        selected={item === selectedBuyList}
                                                        onClick={()=>setSelectedBuyList(item)}
                                                    >
                                                        <ListItemText className='list-text' primary={ item.name } onClick={toggleDrawer(item, true)}/>
                                                        <ListItemSecondaryAction className='btn-wrapper'>
                                                            <IconButton edge="end" aria-label='delete'>
                                                                <Link className='btn-action' onClick={e=>handleArchiveBuyList(item._id)}>Archive</Link>
                                                            </IconButton>
                                                            <IconButton edge="end" aria-label='delete'>
                                                                <Link className='btn-action' onClick={()=>handleDeleteBuyList(item)}>Delete</Link>
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )
                                            }
                                        })
                                   ))
                                }
                            </List>
                        </div>
                        {isLoading && <div className="buy-lists"><Spinner isCentered={true} /></div>}
                        {isFetchingNextPage && <div className="buy-lists"><Spinner isCentered={true} /></div>}
                        {isError && <h2>{error.message}</h2>}
                        {/* <span>{`Min:${minWidth720}, Max:${maxWidth1024}, Combi: ${minWidth720 && maxWidth1024}`}</span> */}
                        {/* {`${total}-${data?.pages[0].data.total}`} */}
                    </div>
                </TabPanel>
                <TabPanel value={tabValue} index='archive-buy-lists'>
                    <div className="buy-lists">
                        <Toast isOpen={isOpen} handleClose={() => setIsOpen(false)} type={toastType}>
                            {toastMessage}
                        </Toast>
                        <div className="controls">
                            <>
                            <input className='search-archive' name='search-archive-buylist' value={archiveInputText} placeholder='Search Archived Buy Lists'
                            onChange={e=>setArchiveInputText(e.target.value)}
                            />
                            </>
                        </div>
                            <div className="list-scroll">
                                <List component='nav'>
                                        { data && data?.pages && data?.pages?.map(page=>(
                                            page?.data?.buyLists?.map((item, i)=>{
                                                if(page.data.buyLists.length === i + 1){
                                                    return (
                                                        <ListItem
                                                            ref={lastBuyListElementRef}
                                                            key={i}
                                                            dense={!(minWidth720 && maxWidth1024) || !minWidth600}
                                                            selected={selectedBuyList === item}
                                                            button
                                                            divider
                                                            onClick={()=>setSelectedBuyList(item)}
                                                        >
                                                            <ListItemText className='list-text' primary={item.name} onClick={toggleDrawer(item, true)}/>
                                                            <ListItemSecondaryAction className='btn-wrapper'>
                                                                <IconButton edge="end" aria-label='restore'>
                                                                    <Link className='btn-action' onClick={()=>handleRestore(item)}>Restore</Link>
                                                                </IconButton>
                                                                <IconButton edge="end" aria-label='delete'>
                                                                    <Link className='btn-action' onClick={()=>handleDeleteBuyList(item)}>Delete</Link>
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    )
                                                }
                                                else{
                                                    return (
                                                        <ListItem
                                                            key={i}
                                                            dense={!(minWidth720 && maxWidth1024) || !minWidth600}
                                                            button
                                                            divider
                                                            selected={selectedBuyList === item}
                                                            onClick={()=>setSelectedBuyList(item)}
                                                        >
                                                            <ListItemText className='list-text' primary={item.name} onClick={toggleDrawer(item, true)}/>
                                                            <ListItemSecondaryAction className='btn-wrapper'>
                                                                <IconButton edge="end" aria-label='restore'>
                                                                <Link className='btn-action' onClick={()=>handleRestore(item)}>Restore</Link>
                                                                </IconButton>
                                                                <IconButton edge="end" aria-label='delete' >
                                                                    <Link className='btn-action' onClick={()=>handleDeleteBuyList(item)}>Delete</Link>
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                        ))}
                                </List>
                            </div>
                        {isLoading && <div className="buy-lists"><Spinner isCentered={true} /></div>}
                        {isFetchingNextPage && <div className="buy-lists"><Spinner isCentered={true} /></div>}
                        {isError && <h2>{error.message}</h2>}
                    </div>
                </TabPanel>
            </div>
        </>
    )
}

export default BuyListGroup;
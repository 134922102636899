import React, { useEffect, useState } from "react"
import './row-edit.scss'
import axios from "axios"

const StoreSection = ({label = '', value = '', fetching, product, inventory, setInventory, setToastMsg, setOpenToast}) => {
    const [supplier, setSupplier] = useState(value)

    useEffect(() => {
        if(product.supplier !== undefined && product.supplier !== null){
            setSupplier(product.supplier)
        }
    }, [fetching])

    const onChange = e => {
        setSupplier(e.target.value)
    }

    const handleChangeSupllier = async(sellerSku, supplier) => {
        const newInventory = inventory.products.filter(p => p._id !== product._id)
        const {data} = await axios.post("api/v1/inventory/set-supplier", null, {params: {sellerSku, supplier}})
        setInventory({products: [...newInventory, {...product, ...data}]})
        setToastMsg('You have successfully set store section.')
        setOpenToast(true)
    }
    const onBlur = e => {
        e.preventDefault()
        if(product.supplier != supplier){
            handleChangeSupllier(product.sellerSku, supplier)
        }
    }
    return(
        <div className="row-edit-container">
            <p className="label">{label}</p>
            <input className="value input left" value={supplier} onChange={onChange} onClick={e=>e.target.select()} onBlur={onBlur}></input>
        </div>
    )
}

export default StoreSection
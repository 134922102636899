import React, { useState, useEffect } from 'react'
import './settings.scss'
import axios from 'axios'
import Toast from '../toast'
import checkPassword from '../../utilities/js/password-checker'

import { Button } from '@material-ui/core'
 
const Settings = ({ setIsRefetchCheckUserEnabled, handleLogout }) => {
  const savedEmail = localStorage.getItem('email')

  //Email
  const [email, setEmail] = useState(savedEmail ? savedEmail : '')
  const [editEmail, setEditEmail] = useState(false)
  const [editEmailPassword, setEditEmailPassword] = useState('')
  const [showEditEmailPassword, setShowEditEmailPassword] = useState(false)
  const [toastMsg, setToastMsg] = useState('')
  const [openToast, setOpenToast] = useState(false)
  const [toastType, setToastType] = useState('error')
  const [updateEmailLoading, setUpdateEmailLoading] = useState(false)

  //Password
  const [editPassword, setEditPassword] = useState(false)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [currentPasswordError, setCurrentPasswordError] = useState('')
  const [newPasswordError, setNewPasswordError] = useState('')
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState('')
  const [updatePasswordLoading, setUpdatePasswordLoading] = useState(false)

  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)

  useEffect(() => {
    localStorage.setItem('password', '')
    localStorage.setItem('newEmail', '')
  }, [])

  
  const handleUpdateEmail = (e) => {
    e.preventDefault()
    setUpdateEmailLoading(true)
    if (editEmailPassword) {
      axios.post('/api/v1/update-email', { email: savedEmail, newEmail: email, password: editEmailPassword } )
         .then(res => {
          setUpdateEmailLoading(false)
          if (res.data.msg === 'success') {
            setToastMsg('')
            setEditEmail(false)
            localStorage.setItem('newEmail', email)
            localStorage.setItem('password', editEmailPassword)
            setIsRefetchCheckUserEnabled(true)
            setOpenToast(true)
            setToastType('success')
            setToastMsg(`A confirmation email was sent to the email address ${savedEmail}.`)
          } else if (res.data.msg === "New Email Address is already taken.") {
            setOpenToast(true)
            setToastMsg(res.data.msg)
            setToastType('error')
            setEmail(savedEmail)
          }
         })
         .catch(err => {
          setUpdateEmailLoading(false)
          setOpenToast(true)
          setToastMsg('Incorrect Password')
          setToastType('error')
         })
    }
  }

  const enableEditEmail = () => {
    setEditEmail(old => !old)
    setEditEmailPassword('')
  }

  const handleUpdatePassword = e => {
    e.preventDefault()
    setUpdatePasswordLoading(true)
    const requestBody = {
      email: savedEmail,
      currentPassword,
      newPassword
    }
    axios.post('/api/v1/update-password-pwa', requestBody)
      .then(res => {
        const { data } = res
        const { status, message } = data
        setUpdatePasswordLoading(false)

        if (status === 'success') {
          setEditPassword(false)
          setOpenToast(true)
          setToastType('success')
          setToastMsg(`${message} Redirecting to login page.`)
          setCurrentPassword('')
          setNewPassword('')
          setConfirmNewPassword('')

          setTimeout(() => {
            handleLogout()
          }, 1500)


        } else if (status === 'failed') {
          setCurrentPasswordError(message)
        }
        

      })
      .catch(err => {
        setUpdatePasswordLoading(false)
      })
  }

  const enableEditPassword = () => {
    setEditPassword(old => !old)
  }

  const currentPasswordOnChange = e => {
    setCurrentPassword(e.target.value)
    setCurrentPasswordError('')
  }

  const newPasswordOnChange = e => {
    setNewPassword(e.target.value)

    if (e.target.value.length < 8) {
      setNewPasswordError('Password must be atleast 8 characters.')
    } else {
      if (checkPassword(e.target.value)) {
        setNewPasswordError('')
      } else {
        setNewPasswordError('Password must contain atleast one uppercase, lowercase, number and special character.')
      }
    }


    if (e.target.value !== confirmNewPassword) {
      setConfirmNewPasswordError('Please make sure your passwords match.')
    } else {
      setConfirmNewPasswordError('')
    }
  }

  const confirmNewPasswordOnChange = e => {
    setConfirmNewPassword(e.target.value)
    if (e.target.value !== newPassword) {
      setConfirmNewPasswordError('Please make sure your passwords match.')
    } else {
      setConfirmNewPasswordError('')
    }
  }

  const cancelEditEmail = () => {
    setEditEmail(false)
    setEmail(savedEmail)
  }

  const cancelEditPassword = () => {
    setEditPassword(false)
    setCurrentPassword('')
    setNewPassword('')
    setConfirmNewPassword('')
  }
  
  const canUpdateEmail = () => {
    if (editEmailPassword) {
      return true
    } else {
      return false
    }
  }

  const canUpdatePassword = () => {
    return currentPassword && !currentPasswordError && newPassword && !newPasswordError && confirmNewPassword && !confirmNewPasswordError 
  }

  return (
    <div className="settings">
      <Toast isOpen={openToast} handleClose={() => setOpenToast(false)} type={toastType} duration={3000} >
        {toastMsg}
      </Toast>
      {/* EMAIL SECTION */}
      <form onSubmit={handleUpdateEmail}>
        { !editEmail && (
          <div className="row">
            <label htmlFor="email">Email Address</label>
            <div className="input-container">
              <input type="email" id="email" value={email} disabled/>
              <button className="edit-input-button" type="button" onClick={enableEditEmail}>Edit</button>
            </div>
          </div>
        ) }

        { editEmail && (
          <>
            <div className="row">
              <label htmlFor="email">Email Address<span style={{color: 'red'}}>*</span></label>
              <input type="email" id="email" value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div className="row">
              <label htmlFor="email-password">Enter Password to Confirm<span style={{color: 'red'}}>*</span></label>
              <div className="input-container">
                <input type={showEditEmailPassword ? 'text' : 'password'} id="email-password" value={editEmailPassword} onChange={e => setEditEmailPassword(e.target.value)}  style={{borderColor: 'var(--replen-gray)' }} />
                <button className="edit-input-button" type="button" onClick={() => setShowEditEmailPassword(old => !old)}>{showEditEmailPassword ? 'Hide': 'Show'}</button>
              </div>
            </div>
              <div className="row" style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
                <button className="cancel-button" type="button" onClick={cancelEditEmail}>Cancel</button>
                <button className="save-button" style={{ opacity: canUpdateEmail() ? updateEmailLoading ? 0.5 : 1 : 0.5, pointerEvents: canUpdateEmail() ? updateEmailLoading ? 'none' : 'unset' : 'none' }} >Save</button>
              </div>
          </>
        ) }
      </form>
      {/* PASSWORD SECTION */}
      <form onSubmit={handleUpdatePassword} style={{marginTop: '1rem'}}>
        {/* PLACEHOLDER PASSWORD */}
          {!editPassword && (
            <div className="row">
              <label htmlFor="password-placeholder">Password</label>
              <div className="input-container">
                <input type="password" id="password-placeholder" value='password' disabled/>
                <button className="edit-input-button" type="button" onClick={enableEditPassword}>Edit</button>
              </div>
            </div>
          )}
          {editPassword && (
            <>
              <div className="row">
                <label htmlFor="current-password">Current Password<span style={{color: 'red'}}>*</span></label>
                <div className="input-container">
                  <input type={showCurrentPassword ? 'text' : 'password'} id="current-password" value={currentPassword} onChange={currentPasswordOnChange} />
                  <button className="edit-input-button" type="button" onClick={() => setShowCurrentPassword(old => !old)}>{showCurrentPassword ? 'Hide' : 'Show'}</button>
                </div>
                <p className="error-message">{currentPasswordError}</p>
              </div>
            
              <div className="row">
                <label htmlFor="new-password">New Password<span style={{color: 'red'}}>*</span></label>
                <div className="input-container">
                  <input type={showNewPassword ? 'text' : 'password'} id="new-password" value={newPassword} onChange={newPasswordOnChange} />
                  <button className="edit-input-button" type="button" onClick={() => setShowNewPassword(old => !old)}>{showNewPassword ? 'Hide' : 'Show'}</button>
                </div>
                <p className="error-message">{newPasswordError}</p>
              </div>
            
              <div className="row">
                <label htmlFor="confirm-new-password">Confirm New Password<span style={{color: 'red'}}>*</span></label>
                <div className="input-container">
                  <input type={showConfirmNewPassword ? 'text' : 'password'} id="confirm-new-password" value={confirmNewPassword} onChange={confirmNewPasswordOnChange} />
                  <button className="edit-input-button" type="button" onClick={() => setShowConfirmNewPassword(old => !old)}>{showConfirmNewPassword ? 'Hide' : 'Show'}</button>
                </div>
                <p className="error-message">{confirmNewPasswordError}</p>
              </div>
              <div className="row" style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
                <button className="cancel-button" type="button" onClick={cancelEditPassword}>Cancel</button>
                <button className="save-button" style={{ opacity: canUpdatePassword() ? updatePasswordLoading ? 0.5 : 1 : 0.5, pointerEvents: canUpdatePassword() ? updatePasswordLoading ? 'none' : 'unset' : 'none' }} >Save</button>
              </div>
            </>
          )}

      </form>
    </div>
  )
}

export default Settings
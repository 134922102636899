import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { Close } from '@material-ui/icons'

import { taxCodes, sections } from './tax-code-table'
import './tax-code-input.scss'

const TaxCodeInput = ({ selectedTaxCode, setTaxCode }) => {
  const [ search, setSearch ] = useState(selectedTaxCode)
  const [ isOpen, setIsOpen ] = useState(false)

  const searchBarRef = useRef(null)
  const selectedTaxCodeRef = useRef(null)
  const resultsContainerRef = useRef(null)
  const normalizedSearch = search.toLowerCase().trim()

  const taxCodeFilter = taxCode => {
    const taxCodeIncludesSearch = taxCode.toLowerCase().includes(normalizedSearch)
    const descriptionIncludesSearch = taxCodes[taxCode].toLowerCase().includes(normalizedSearch)

    return taxCodeIncludesSearch || descriptionIncludesSearch
  }

  const handleTaxCodeClick = newTaxCode => () => {
    setIsOpen(false)
    setTaxCode(newTaxCode)
  }

  useEffect(() => {
    if (isOpen) {
      setSearch("")
      searchBarRef.current.focus()
      selectedTaxCodeRef.current.scrollIntoView()
    }
  }, [isOpen])

  return (
    <>
      <input value={selectedTaxCode} onClick={() => setIsOpen(true)} style={{ flexGrow: 1 }} readOnly />
      {
        // Put it in a portal for now because of style scoping problems, will refactor later
        ReactDOM.createPortal(
          <div className={`tax-code-search${isOpen ? " active" : ""}`}>
            <div className="search-header">
              <div className="search-title">Search Tax Code</div>
              <button className="search-close" onClick={() => setIsOpen(false)}>
                <Close /> Close
              </button>
              <input 
                type="search" className="search-bar" placeholder="Input the tax code (or its description / section)"
                ref={searchBarRef} value={search} onInput={e => setSearch(e.target.value)}
              />
            </div>
            <div className="results-container" ref={resultsContainerRef}>
              {
                Object.entries(sections).map(([ section, sectionTaxCodes ]) => {
                  const titleIncludesSearch = section.toLowerCase().includes(normalizedSearch)
                  const filteredTaxCodes = sectionTaxCodes.filter(taxCodeFilter)

                  return titleIncludesSearch || filteredTaxCodes.length
                    ? <div className="section" key={section}>
                        <div className="title">{section}</div>
                        {
                          (filteredTaxCodes.length ? filteredTaxCodes : sectionTaxCodes).map(taxCode => {
                            const isSelected = taxCode === selectedTaxCode
                            const taxCodeClass = `tax-code${isSelected ? " active" : ""}`
                            
                            return (
                              <div className={taxCodeClass} onClick={handleTaxCodeClick(taxCode)} key={taxCode} ref={isSelected ? selectedTaxCodeRef : null}>
                                <span>{taxCode}</span>
                                <span>{taxCodes[taxCode]}</span>
                              </div>
                            )
                          })
                        }
                      </div>
                    : null
                })
              }
            </div>
          </div>,
          document.body
        )
      }
    </>
  )
}

export default TaxCodeInput
import React, { useEffect, useState } from "react"
import { useBuyLitsItems } from './useBuyListItemHelper'
import { ArrowForward } from '@material-ui/icons'
import { Button, Divider, FormControlLabel, Switch } from '@material-ui/core'
import Spinner from '../spinner'
import './buy-list-item.scss'
import numberToUSD from '../../utilities/js/number-to-usd'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { formatValue } from 'react-currency-input-field'
import { groupBy } from 'lodash'
import moment from 'moment'

const BuyListItems = ({ buylist, toggleDrawer, user }) => {
    const { isLoading, isFetching, data: buyListData } = useBuyLitsItems(buylist.userId, buylist._id, user)
    const minWidth600 = useMediaQuery('(min-width:600px)')
    const minWidth720 = useMediaQuery('(min-width:720px)')
    const maxWidth1024 = useMediaQuery('(max-width:1024px)')

    const [consolidate, setConsolidate] = useState(false)
    const [consoBuyList, setConsoBuyList] = useState([])

    const totalBuyListCost = consoBuyList? consoBuyList.reduce((total, item) => total + item.quantity * item.costUnit, 0):0
    const totalBuyListQtyQty = consoBuyList? consoBuyList.reduce((total, item) => total + item.quantity, 0):0

    useEffect(() => {
        if(consolidate){
            setConsoBuyList(consolidateBuylist(buyListData))
        }
        else{
            setConsoBuyList(buyListData)
        }
    }, [consolidate, buyListData])

    const consolidateBuylist = (arr) => {
        let groupSku = groupBy(arr, (list) => (list.msku));
        groupSku = Object.values(groupSku);

        // group the buylist and add all the quantity and calculate the cost unit
        groupSku = groupSku.map((x) => {
          const avg = x.reduce((total, item) => {
            if (item.costUnit) {
                total.cost += item.costUnit * item.quantity
            }

            if(item.quantity){
                total.quantity += item.quantity
            }
            return total;
          }, { cost: 0, quantity: 0 })

          avg.cost = (avg.cost / avg.quantity) || 0;
          avg.quantity = avg.quantity / x.length;

          const result = x.reduce((total, item) => {
            total.costUnit += (item.costUnit || avg.cost) * item.quantity
            total.quantity += item.quantity
            const temp = new Date(total.expirationDate) < new Date(item.expirationDate) ? total.expirationDate || item.expirationDate : item.expirationDate
            if (temp) total.expirationDate = temp
            const condition = (total.condition !== "Used" && item.condition === "Used")? "Used": "New";
            if(condition) total.condition = condition

            return total;
          }, { ...JSON.parse(JSON.stringify(x[0])), costUnit: 0, quantity: 0 });

          if (result.quantity > 0) {
            result.costUnit = (result.costUnit / result.quantity) || 0;// calculate the total costunit and quantity;
          } else {
            result.costUnit = 0;
          }
          return result;
        })
        return groupSku
    }

    const handleCheck = (event) => {
        setConsolidate(event.target.checked)
    }

    const formatCost = (cost) => formatValue({
        value: String(cost),
        prefix: "$",
        decimalScale: 2
      });

    return (
        <>
            <div className="buy-list-header">
                {
                    !isLoading && !isFetching &&
                    <div className={(minWidth600 || (minWidth720 && maxWidth1024))?'nav-hide':'nav-show'}>
                        <Button className="back-btn" size="large">
                            <ArrowForward fontSize="large" onClick={toggleDrawer(buylist, false)}/>
                        </Button>
                    </div>
                }
                { !isLoading && !isFetching && <div className="buy-list-title">{`"${buylist.name}"`}</div> }
            </div>
            <Divider />
            {/* <FormControlLabel
                style={{alignSelf:'center'}}
                control={<Switch checked={checked} onChange={handleCheck} name="checkedA" />}
                color='secondary'
                label="Show Child Items"
            /> */}
            {/* <Divider /> */}
            <FormControlLabel
                style={{alignSelf:'center'}}
                control={<Switch checked={consolidate} onChange={handleCheck} name="checkedA" />}
                color='secondary'
                label="Consolidate purchases for the same product SKU"
            />
            <Divider />
            <div className="buy-list-container">
                <div className="buy-list-items">
                {
                    isLoading || isFetching ? <Spinner isCentered={true} type={'overlay'}/>:
                    consoBuyList && consoBuyList?.map((buyList, i) => {
                        const { imageUrl, title, customTitle,asin, quantity, expirationDate, condition, costUnit } = buyList
                        const thumbnail = imageUrl
                        // Fetch 96×96px image (even with 48×48px container) for better quality in high DPI devices
                        ? `https://images-na.ssl-images-amazon.com/images/I/${imageUrl.split(".")[0]}._AC_US96_.jpg`
                        // Else, just load the "No Image Available" placeholder
                        : "/assets/images/no-image-available.svg"
                        return(
                            <div key={i} className="shopping-list-item">
                                <img className="thumbnail" src={thumbnail} />
                                <div className="title">{customTitle??title}</div>
                                <div className="props">
                                    <div className="prop">
                                        <label>ASIN</label>
                                        <div>{asin}</div>
                                    </div>
                                    <div className="prop">
                                        <label>Cost</label>
                                        <div>{costUnit?numberToUSD(costUnit):'N/A'}</div>
                                    </div>
                                    <div className="prop">
                                        <label>QTY</label>
                                        <div>{quantity}</div>
                                    </div>
                                    <div className="prop">
                                        <label>Condition</label>
                                        <div>{condition??'N/A'}</div>
                                    </div>
                                    <div className="prop">
                                        <label>Expiry Date</label>
                                        <div>{(expirationDate)?expirationDate?.trim()===''?'N/A':moment(expirationDate).local().format('ll'):'N/A'}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {/* {
                    cartJournalData?.data && checked && cartJournalData?.data.map((cartJournal, i) => {
                        const { imageUrl, decidedBuyQuantity, isAlwaysBuyList, title, customTitle,
                        asin, supplier, storeSection } = cartJournal.product
                        // const { costPrice, condition, expiryDate } = buyList.otherDetails
                        const { quantity, condition, costUnit } = cartJournal
                        const { expiryDate } = cartJournal._doc
                        //const isZeroBuyQty = decidedBuyQuantity === 0
                        //const shoppingListItemClass = `shopping-list-item${isZeroBuyQty && !isAlwaysBuyList ? " zero-buy-qty" : ""}`
                        const thumbnail = imageUrl
                        // Fetch 96×96px image (even with 48×48px container) for better quality in high DPI devices
                        ? `https://images-na.ssl-images-amazon.com/images/I/${imageUrl.split(".")[0]}._AC_US96_.jpg`
                        // Else, just load the "No Image Available" placeholder
                        : "/assets/images/no-image-available.svg"
                        return(
                            <div key={i} className="shopping-list-item">
                                <img className="thumbnail" src={thumbnail} />
                                <div className="title">{customTitle??title}</div>
                                <div className="props">
                                    <div className="prop">
                                        <label>ASIN</label>
                                        <div>{asin}</div>
                                    </div>
                                    <div className="prop">
                                        <label>Cost</label>
                                        <div>{costUnit?numberToUSD(costUnit):'N/A'}</div>
                                    </div>
                                    <div className="prop">
                                        <label>QTY</label>
                                        <div>{quantity}</div>
                                    </div>
                                    <div className="prop">
                                        <label>Condition</label>
                                        <div>{condition??'N/A'}</div>
                                    </div>
                                    <div className="prop">
                                        <label>Expiry Date</label>
                                        <div>{(expiryDate)?expiryDate?.trim()===''?'N/A':moment(expiryDate).local().format('ll'):'N/A'}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    } */}
                {
                    buyListData?.length === 0 && <div className="empty-buy-list">This buy list is empty</div>
                }
                </div>
                {/* { isError && <div className="error">{error.message}</div> } */}
            </div>
            {
                 !isLoading || isFetching?
                <div className="buy-list-footer">
                    Total Cost :
                    <span className="total-cost"> {formatCost(totalBuyListCost)}</span>
                    <span className="total-qty"> ({totalBuyListQtyQty} {totalBuyListQtyQty===1?'item':'items'})</span>
                </div>:null
            }
        </>
    )
}

export default BuyListItems
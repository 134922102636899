import React, { useState } from 'react'
import { Switch, Route } from 'react-router-dom'

import ShoppingList from '../shopping-list'
import ShoppedList from '../shopped-list'
import Settings from '../settings'
import Dashboard from '../dashboard'
import Inventory from '../inventory'
import InventoryItem from '../inventory/inventory-item'
import UnresolvedProducts from '../unresolved-products'
import Packing from '../packing'
import BuyListGroup from '../buy-list-group'
import './content.scss'

const Content = ({ isLongPress, setIsLongPress, setDeleteFunction, selectedDelete, setSelectedDelete, setIsRefetchCheckUserEnabled, handleLogout, setNewCartItems, item, setItem, openModal, setOpenModal, user, skip, setSkip, inventory, setInventory }) => {

  return (
    <main className="content">
      <Switch>
        <Route path="/shopping-list" render={ ( props ) => <ShoppingList { ...props } isLongPress={isLongPress} setIsLongPress={setIsLongPress} setDeleteFunction={setDeleteFunction} selectedDelete={selectedDelete} setSelectedDelete={setSelectedDelete} setNewCartItems={setNewCartItems} user={user}/> }  />
        {/* <Route path="/shopped-list" component={ShoppedList} /> */}
        <Route path="/shopped-list" render={ ( props ) => <ShoppedList { ...props } isLongPress={isLongPress} setIsLongPress={setIsLongPress} setDeleteFunction={setDeleteFunction} selectedDelete={selectedDelete} setSelectedDelete={setSelectedDelete} setNewCartItems={setNewCartItems} user={user}/> } />
        <Route exact path="/settings" render={ ( props ) => <Settings {...props} setIsRefetchCheckUserEnabled={setIsRefetchCheckUserEnabled} handleLogout={handleLogout} /> } />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/inventory" render={ ( props ) => <Inventory { ...props } skip={skip} setSkip={setSkip} inventory={inventory} setInventory={setInventory}/> } />
        <Route exact path="/inventory/:productId" render={ ( props ) => <InventoryItem { ...props } inventory={inventory} setInventory={setInventory} user={user} /> } />
        <Route exact path="/unresolved-products" component={UnresolvedProducts} />
        <Route exact path="/unresolved-products/pack/:childId" render={ ( props ) => <Packing {...props} item={item} setItem={setItem} openModal={openModal} setOpenModal={setOpenModal}/> } />
        <Route exact path="/buy-list-group" render = {(props) => <BuyListGroup {...props} user={user} />} />
      </Switch>
    </main>
  )
}

export default Content
import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'

import './accordionFlex.scss'

const BuyBox = ({ expanded, setExpanded, product }) => {
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }
    const { buyQuantity30Days, totalOrdersLast30Days, salesLast30Days, salesAmountLast30Days, totalPendingOrdersLast30Days, afnTotalQuantity, localInventory, buyBoxPrice, lowestOfferPrice, lowestFBAOfferPrice, noOfSellers, salesRank } = product

    const expandedRows = [
        { label: 'lowest offer price', value: `$${lowestOfferPrice ?? 0}`},
        { label: 'lowest FBA offer price', value: `$${lowestFBAOfferPrice ?? 0}`},
        { label: 'sale amount', value: `$${salesAmountLast30Days ?? 0}`},
        { label: 'no. of sellers', value: noOfSellers ?? 0},
        { label: 'sales rank', value: salesRank ?? 0},
    ]

    return (
        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} style={{borderBottom: '1px solid var(--replen-light-bg)'}}>
            <AccordionSummary expandIcon={<ArrowDropDown />} aria-controls="panel-content" id="panel-header" >
                <div className="accordion-summary">
                    <p className="label">Buy Box Price</p>
                    <p className="value">${buyBoxPrice ?? 0}</p>
                </div>
            </AccordionSummary>
            <AccordionDetails id="panel-body">
                <div className="accordion-body">
                    {expandedRows.map((row, index) => (
                        <div className="accordion-body-row" key={index}>
                            <p className="label">{row.label}:</p>
                            <p className="value">{row.value}</p>
                        </div>
                    ))}
                </div>
            </AccordionDetails>
            
        </Accordion>
    )
}

export default BuyBox

import React, { useState, useEffect } from 'react'
import axios from 'axios'
import UnresolvedListItem from './unresolved-list-item'
import ConfirmResolve from './resolve-modal'
import Spinner from '../spinner'

import './unresolved-products.scss'

const UnresolvedProducts = () => {
    const [rows, setRows] = useState([])

    // For modal
    const [openModal, setOpenModal] = useState(false)
    const [item, setItem] = useState({})
    
    //For spinner
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        axios.get('/api/v1/child-inventory/unresolved')
             .then(({ data }) => {
                 setRows(data.data)
                 setIsLoading(false)
             })
    }, [])

    return (
        <div className="unresolved-products">
            <ConfirmResolve open={openModal} setOpen={setOpenModal} item={item} setItem={setItem} setRows={setRows} setIsLoading={setIsLoading} />
            {isLoading && <Spinner isCentered={true} type="overlay" />}
            { rows.length > 0 && (
                rows.map((row, index) => (
                    <UnresolvedListItem row={row} key={index} setOpenModal={setOpenModal} setItem={setItem}/>
                ))
            ) }
        </div>
    )
}

export default UnresolvedProducts

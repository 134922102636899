import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'

import './accordionFlex.scss'

const SixtyDays = ({ expanded, setExpanded, product }) => {
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }
    const { buyQuantity60Days, totalOrdersLast60Days, salesLast60Days, salesAmountLast60Days, totalPendingOrdersLast60Days, afnTotalQuantity, localInventory, decidedBuyQuantity } = product

    let adjusted60BuyQty = 0;
    if(!isNaN(buyQuantity60Days))
    {
      adjusted60BuyQty = buyQuantity60Days;
      if(!isNaN(localInventory))
      {
        adjusted60BuyQty = buyQuantity60Days - localInventory;
      }
      if(!isNaN(decidedBuyQuantity))
      {
        adjusted60BuyQty = adjusted60BuyQty - decidedBuyQuantity;
      }
    }

    const expandedRows = [
        { label: 'orders', value: totalOrdersLast60Days ?? 0},
        { label: 'units', value: salesLast60Days ?? 0},
        { label: 'sale amount', value: `$${salesAmountLast60Days ?? 0}`},
        { label: 'pending orders', value: totalPendingOrdersLast60Days ?? 0},
        { label: 'inventory qty', value: afnTotalQuantity ?? 0},
        { label: 'local qty', value: localInventory ?? 0},
        { label: 'recommended buy list adj', value: adjusted60BuyQty ?? 0}
    ]

    return (
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{borderBottom: '1px solid var(--replen-light-bg)'}}>
            <AccordionSummary expandIcon={<ArrowDropDown />} aria-controls="panel-content" id="panel-header" >
                <div className="accordion-summary">
                    <p className="label">60 Days</p>
                    <p className="value">{adjusted60BuyQty ?? 0}</p>
                </div>
            </AccordionSummary>
            <AccordionDetails id="panel-body">
                <div className="accordion-body">
                    {expandedRows.map((row, index) => (
                        <div className="accordion-body-row" key={index}>
                            <p className="label">{row.label}:</p>
                            <p className="value">{row.value}</p>
                        </div>
                    ))}
                </div>
            </AccordionDetails>
            
        </Accordion>
    )
}

export default SixtyDays

import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'

import './accordionFlex.scss'

const ThirtyDays = ({ expanded, setExpanded, product }) => {
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }
    const { buyQuantity30Days, totalOrdersLast30Days, salesLast30Days, salesAmountLast30Days, totalPendingOrdersLast30Days, afnTotalQuantity, localInventory, decidedBuyQuantity } = product

    let adjusted30BuyQty = 0;
    if(!isNaN(buyQuantity30Days))
    {
      adjusted30BuyQty = buyQuantity30Days;
      if(!isNaN(localInventory))
      {
        adjusted30BuyQty = buyQuantity30Days - localInventory;
      }
      if(!isNaN(decidedBuyQuantity))
      {
        adjusted30BuyQty = adjusted30BuyQty - decidedBuyQuantity;
      }
    }

    const expandedRows = [
        { label: 'orders', value: totalOrdersLast30Days ?? 0},
        { label: 'units', value: salesLast30Days ?? 0},
        { label: 'sale amount', value: `$${salesAmountLast30Days ?? 0}`},
        { label: 'pending orders', value: totalPendingOrdersLast30Days ?? 0},
        { label: 'inventory qty', value: afnTotalQuantity ?? 0},
        { label: 'local qty', value: localInventory ?? 0},
        { label: 'recommended buy list adj', value: adjusted30BuyQty ?? 0}
    ]

    return (
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{borderBottom: '1px solid var(--replen-light-bg)'}}>
            <AccordionSummary expandIcon={<ArrowDropDown />} aria-controls="panel-content" id="panel-header" >
                <div className="accordion-summary">
                    <p className="label">30 Days</p>
                    <p className="value">{adjusted30BuyQty ?? 0}</p>
                </div>
            </AccordionSummary>
            <AccordionDetails id="panel-body">
                <div className="accordion-body">
                    {expandedRows.map((row, index) => (
                        <div className="accordion-body-row" key={index}>
                            <p className="label">{row.label}:</p>
                            <p className="value">{row.value}</p>
                        </div>
                    ))}
                </div>
            </AccordionDetails>
            
        </Accordion>
    )
}

export default ThirtyDays

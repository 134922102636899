import React, { useEffect, useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'
import axios from 'axios'
import './accordionGrid.scss'
import moneyFormatter from '../../../../utilities/js/moneyFormatter'

import { chooseDefaultCalculationPrice, GetProfitEstimations } from '../../../../utilities/functions'

const Cost = ({ expanded, setExpanded, product, setProduct, fetching, productCopy, setOpenToast, setToastMsg, setInventory, inventory }) => {
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }
    const {
        buyBoxPrice,
        costPrice,
        fee,
        shipBy,
        packageDimensions,
        shippingRate,
        profit,
        roiPercentage,
        profitPercentage,
        sellerSku,
        _id: productId,
        profitCalculatedBy,
        listPrice,
        lowestOfferPrice,
        lowestFBAOfferPrice,
        weight,
        defaultPrice,
        fbmFee,
        fulfilmentType,
        showPrepCenterFees,
        prepCenterFees,
        showLILF,
        lowInventoryLevelFee,
        showFBAIPSF,
        placementServiceFee,
    } = product

    const dateNow = new Date()
    const monthlyStorage = [9,10,11].includes(dateNow.getMonth()) ? fee?.monthlyStorageFeeOct : fee?.monthlyStorageFeeJan

    const [cost, setCost] = useState(0)

    // const firstRowLabel = profitCalculatedBy == 'buyBoxPrice' && !buyBoxPrice && listPrice ||  profitCalculatedBy == 'lowestFBAOfferPrice' && !lowestFBAOfferPrice && listPrice ||  profitCalculatedBy == 'lowestOfferPrice' && !lowestOfferPrice && listPrice ?

    const firstRowLabel = profitCalculatedBy => {
        if((profitCalculatedBy == 'buyBoxPrice' && !buyBoxPrice && lowestFBAOfferPrice) || (profitCalculatedBy == 'lowestOfferPrice' && !lowestOfferPrice && lowestFBAOfferPrice)){
            return 'Lowest FBA Offer Price'
        }
        else if((profitCalculatedBy == 'buyBoxPrice' && !buyBoxPrice && listPrice) || (profitCalculatedBy == 'lowestOfferPrice' && !lowestOfferPrice && listPrice) || (profitCalculatedBy == 'lowestFBAOfferPrice' && !lowestFBAOfferPrice && !buyBoxPrice && listPrice)){
            return 'Sale Price'
        }
        else if(profitCalculatedBy == 'lowestFBAOfferPrice' && !lowestFBAOfferPrice && buyBoxPrice){
            return 'Buybox Price'
        }
        else if(!profitCalculatedBy){
            return 'Sale Price'
        }
        else if (profitCalculatedBy == 'listPrice'){
            return 'Sale Price'
        }
        else if(profitCalculatedBy == 'buyBoxPrice'){
            return 'Buy Box Price'
        }
        else if(profitCalculatedBy == 'lowestFBAOfferPrice'){
            return 'Lowest FBA Offer Price'
        }
        else if(profitCalculatedBy == 'lowestOfferPrice'){
            return 'Lowest Offer Price'
        }
        else return ""
    }

    const expandedRows = [
        { label: firstRowLabel(profitCalculatedBy), value: `$${defaultPrice ?? 0}`},
        { label: 'cost', value: `$${costPrice ?? 0}`},
        { label: 'referral', value: `$${(defaultPrice * (fee ? (fee.feePercentage / 100) : 0 )).toFixed(2) ?? 0}`},
        { label: 'FBA', value: fee && fee.otherFees? fee.otherFees: 0},
        ...fulfilmentType != 'Merchant'? [{ label: 'storage', value: `$${monthlyStorage ?? 0}`}]: [],
        ...fulfilmentType != 'Merchant'? [{ label: 'inbound(per item)', value: `$${(shipBy ?shipBy === 'byItem' ? shippingRate : (packageDimensions ? packageDimensions?.weight*shippingRate : weight ? weight*shippingRate : 0 ) : 0).toFixed(2)}`}]: [],
        ...fbmFee && fulfilmentType == 'Merchant'? [{ label: 'FBM Fee', value: `$${fbmFee}` }]: [],
        ...showPrepCenterFees? [{ label: 'Prep. Center Fees', value: moneyFormatter.format(prepCenterFees ?? 0) }]: [],
        ...showLILF? [{ label: 'Low Inventory Level Fee', value: moneyFormatter.format(lowInventoryLevelFee ?? 0) }]: [],
        ...showFBAIPSF? [{ label: 'FBA Inbound Placement Service', value: moneyFormatter.format(placementServiceFee ?? 0) }]: [],
        { label: 'profit', value: defaultPrice != 0 ? moneyFormatter.format(profit ?? 0) : "N/A" },
        { label: 'profit', value: defaultPrice != 0 ? moneyFormatter.format(profit ?? 0) : "N/A" },
        { label: 'roi', value: roiPercentage? `${roiPercentage}%` : 'No Cost' },
        { label: 'margin', value: `${profitPercentage}%`},
    ]

    useEffect(() => {
        if (costPrice !== undefined && costPrice !== null) {
            setCost(costPrice)
        }
    }, [fetching])

    const handleChangeCost = e => {
        e.preventDefault()
        if (product.costPrice != cost) {
            setCostPrice(sellerSku, cost)
        }
    }

    const setCostPrice = async (sku, newCost) => {
        const filteredInventory = inventory.products.filter(item => item._id !== product._id)
        const { data } = await axios.post('/api/v1/inventory/set-cost-price', null, { params: { sellerSku: sku, costPrice: newCost === '' ? 0 : newCost } })
        const { profitCalculatedBy, listPrice, buyBoxPrice, lowestOfferPrice, lowestFBAOfferPrice, costPrice, fee, fulfilmentType, shipBy, shippingRate, prepCenterFees, weight, enrolledInSnL, fbmFee } = data
        const defaultPrice = chooseDefaultCalculationPrice({ profitCalculatedBy, listPrice, buyBoxPrice, lowestOfferPrice, lowestFBAOfferPrice })
        const updated_data = GetProfitEstimations({costPrice, defaultPrice, fee, fulfilmentType, shipBy, shippingRate, prepCenterFees, weight, fbmFee, buyBoxPrice});

        setInventory({products: [...filteredInventory, {...product, ...data, ...updated_data, defaultPrice}]})
        setProduct(old => ({ ...old, ...data,...updated_data, defaultPrice}))
        setToastMsg('You have successfully set cost.')
        setOpenToast(true)
    } 

    const handleOnChangeCost = e => {
        const checkIfContainDecimal = e.target.value.toString().split('.')

        if (checkIfContainDecimal.length === 2) {
            checkIfContainDecimal[1] = checkIfContainDecimal[1].substring(0, 2)
            const filteredCost = checkIfContainDecimal.join('.')
            setCost(filteredCost)
        } else {
            setCost(e.target.value)
        }
    }


    return (
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} style={{borderBottom: '1px solid var(--replen-light-bg)'}}>
            <AccordionSummary expandIcon={<ArrowDropDown />} aria-controls="panel-content" id="panel-header" >
                <div className="accordion-summary">
                    <div>
                        <p className="label">Cost</p>
                        <form onSubmit={handleChangeCost} className="left">
                            <input type="number" step="any" value={cost} onChange={handleOnChangeCost} className="value input left" onClick={e => e.target.select()} onBlur={handleChangeCost} />
                        </form>
                    </div>
                    <div>
                        <p className="label" style={{textAlign: 'center'}}>ROI</p>
                        <p className="value" style={{textAlign: 'center'}}>{roiPercentage? `${roiPercentage}%` : 'No Cost'}</p>
                    </div>
                    <div>
                        <p className="label" style={{textAlign: 'right'}}>Profit</p>
                        {/* <p className="value" style={{textAlign: 'right'}}>{profit >= 0 ? `$${profit ?? 0}` : `-$${(profit ?? 0) * -1}`}</p> */}
                        <p className="value" style={{textAlign: 'right'}}>{defaultPrice != 0 ? moneyFormatter.format(profit ?? 0) : "N/A"}</p>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails id="panel-body">
                <div className="accordion-body">
                    {expandedRows.map((row, index) => (
                        <div className="accordion-body-row" key={index}>
                            <p className={row.label === 'profit' ? 'label highlight' : 'label'}>{row.label}:</p>
                            <p className={row.label === 'profit' ? 'value highlight' : 'value'}>{row.value}</p>
                        </div>
                    ))}
                </div>
            </AccordionDetails>
            
        </Accordion>
    )
}

export default Cost

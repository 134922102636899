import React from 'react'
import { CircularProgress } from '@material-ui/core'

import './spinner.scss'

const Spinner = ({ size = 40, isCentered = false, type = 'loading' }) => (
  <>
    {
      type === 'overlay' ? (
        <div className="container" style={{zIndex: 99}}>
          <CircularProgress 
            size={size}
            className={`orange-spinner${isCentered ? " is-centered" : ""}`}
          />
        </div>
      ) : (
        <CircularProgress 
          size={size}
          className={`orange-spinner${isCentered ? " is-centered" : ""}`}
        />
      )
    }
  </>
)

export default Spinner
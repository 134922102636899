import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
     List, ListItem, ListItemText, ListItemSecondaryAction, Radio, Button } from "@material-ui/core"
import { useState } from 'react'
import './buy-list-merge-selection.scss'

const BuyListMergeSelection = ({showBuyListMerge, setShowBuyListMerge, buyListArray, handlePack }) => {

    const [selectedValue, setSelectedValue] = useState()

    const handleChange = event =>{
        setSelectedValue(event.target.value);
    }

    const handleCancel = () => {
        setShowBuyListMerge(false)
    }

    const handleOk = () => {
       if(selectedValue){
        handlePack(selectedValue)
        setShowBuyListMerge(false)
       }
    }

    return (
        <>
            <Dialog
                aria-labelledby='form-dialog-title'
                open={showBuyListMerge}
            >
                <DialogTitle>Buy List Selection</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Child items belongs to more than one(1) buy list, <br/> Please select a buy list for the item to pack.
                    </DialogContentText>
                    <List component='nav' aria-labelledby='main-buylist'>
                    {
                        buyListArray.map(value=>{
                            const arrValue = value.split('||')
                            return(
                                <ListItem key={arrValue[0]}>
                                    <ListItemText primary={`${arrValue[1]}`}/>
                                    <ListItemSecondaryAction>
                                        <Radio edge='end' value={arrValue[0]} checked={arrValue[0]==selectedValue} onChange={handleChange} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        })
                    }
                    </List>
                </DialogContent>
                <DialogActions className='action-section'>
                    <Button className='action-btn-secondary' size='small' onClick={handleCancel}>Cancel</Button>
                    <Button className='action-btn-primary' size='small' onClick={handleOk}>Ok</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default BuyListMergeSelection
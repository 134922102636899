import React, { useState } from 'react'
import { useLocation, useRouteMatch, Link } from 'react-router-dom'
import { ChevronLeft, ExitToApp, HistoryOutlined, Settings, PlaylistAddCheck } from '@material-ui/icons'
import { Avatar, Backdrop, Paper, Tooltip } from '@material-ui/core'
import './header.scss'

const Header = ({ user, handleLogout, isLongPress, setIsLongPress, setSelectedDelete, setOpenModal: setOpenResolveModal }) => {
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const { pathname } = useLocation()
  const { path } = ( useRouteMatch('/shopping-list/:fnsku') || useRouteMatch('/shopped-list/:fnsku') || useRouteMatch('/cart/sent') ) ?? {}
  const { path: pathInventory } = ( useRouteMatch('/inventory/:productId') ) ?? {}
  
  const pathToTitleMap = {
    '/dashboard': "Dashboard",
    '/shopping-list': "Shopping List",
    '/shopped-list': "Shopped List",
    '/settings': "Settings",
    '/cart': "Cart",
    '/inventory': 'Inventory'
  }

  const title = path ? path == '/cart/sent' ? 'Cart' : "Bought Qty" : pathToTitleMap[pathname]
  const isOnBoughtQtyPage = path !== undefined

  const toggleModal = event => {
    if (event.target)
    setIsModalOpen(isOpen => !isOpen)
  }

  const handleCancelDelete = e => {
    e.preventDefault()
    setIsLongPress(false)
    setSelectedDelete(null)
  }

  const dateSixMonthsAgo = new Date(Date.now())
  dateSixMonthsAgo.setMonth(dateSixMonthsAgo.getMonth() - 6)
  const dateNow = new Date(Date.now()).toLocaleDateString()

  return (
    <header className="header">
      <img className="header-logo" src="/assets/images/replen-dashboard-logo-dark-trimmed.svg" />
      {
        isOnBoughtQtyPage || !pathInventory && 
        <div className="icons-container">
          <div className='shopped-list-container' style={{backgroundColor: pathname === '/buy-list-group'? '#F93402' : 'unset' }}>
            <Link to="/buy-list-group" className="shopped-list-button" style={{color: pathname === '/buy-list-group'? 'white' : 'unset' }}>
              <Tooltip title='Buy Lists' arrow>
                <PlaylistAddCheck className='icon-buy-list' />
              </Tooltip>
            </Link>
          </div>
          <div className="shopped-list-container" style={{backgroundColor: pathname === '/shopped-list' ? '#F93402' : 'unset' }}>
            <Link to="/shopped-list" className="shopped-list-button" style={{color: pathname === '/shopped-list' ? 'white' : 'unset' }} >
              <Tooltip title="Shopped List" arrow>
                <HistoryOutlined className="icon-history" />
              </Tooltip>
            </Link>
          </div>
           <Avatar className="header-avatar" onClick={toggleModal} alt={user?.name} />
        </div>
      }
      {

        <div className="title-container">
          <h1 className="page-title" style={{ display: title === 'Bought Qty' && 'block', textAlign: (title === 'Bought Qty' || title === 'Cart') ? 'right'  : 'unset', width: '100%' }}>{title}</h1>
          { isOnBoughtQtyPage && <p className="title-subtext">Transactions between {dateSixMonthsAgo.toLocaleDateString()} - {dateNow}</p>}
        </div>
        
      }
      {
        isOnBoughtQtyPage &&
        (
          isLongPress ? (
            <Link to={path.replace('/:fnsku', '')} onClick={handleCancelDelete} className="back-button" style={{ paddingLeft: '20px' }}>
               Cancel
            </Link>
          ) : (
            <Link to={title == 'Bought Qty' ? path.replace('/:fnsku', '') : path.replace('/sent', '')} className="back-button">
              <ChevronLeft fontSize="large" /> Back
            </Link>
          )
        )
      }
      {
        pathInventory && (
          <Link to={pathInventory.replace('/:productId', '')} className="back-button">
            <ChevronLeft fontSize="large" /> Back
          </Link>
        )
      }
      <Backdrop className="modal" open={isModalOpen} onClick={toggleModal}>
        <Paper variant="outlined" className="modal-menu-container">
          <Avatar className="modal-avatar" alt={user?.name} />
          <div>
            {user?.name}
          </div>
          <Link to='/settings'>
            <button className="settings-button">
              <Settings /> Settings
            </button>
          </Link>
          <button className="logout-button" onClick={handleLogout}>
            <ExitToApp /> Logout
          </button>
        </Paper>
      </Backdrop>
    </header>
  )
}

export default Header
import React from 'react'
import { Link } from 'react-router-dom'
import { ChevronRight } from '@material-ui/icons'
import './inventory-list-item.scss'

const InventoryListItem = ({ inventoryItem }) => {
    const {
      title,
      imageUrl,
      asin,
      sellerSku,
      localInventory,
      _id: productId,
      
      fulfilmentType,
      afnInboundWorkingQuantity,
      afnInboundShippedQuantity,
      afnInboundReceivingQuantity,
      afnResearchingQuantity,
      sellableQuantity,
      afnUnsellableQuantity,

      // for reserved qty
      afnReservedQuantityCustOrder,
      afnReservedQuantityFcProc,
      afnReservedQuantityFcTrans,
      afnReservedQuantity,
      afnTotalQuantity,
    } = inventoryItem
    
    // for reserved qty
    const newAfnReservedQuantity = (afnReservedQuantityCustOrder ?? 0) + (afnReservedQuantityFcProc ?? 0) + (afnReservedQuantityFcTrans ?? 0);
    
    let newAfnTotalQuantity = 0;
      
    if (fulfilmentType != "Merchant") {
      newAfnTotalQuantity = (afnInboundWorkingQuantity ?? 0) + (afnInboundShippedQuantity ?? 0) + (afnInboundReceivingQuantity ?? 0) + newAfnReservedQuantity + (afnResearchingQuantity ?? 0);
    }
    
    newAfnTotalQuantity += (sellableQuantity ?? 0);

    const thumbnail = imageUrl
    // Fetch 96×96px image (even with 48×48px container) for better quality in high DPI devices
    ? `https://images-na.ssl-images-amazon.com/images/I/${imageUrl.split(".")[0]}._AC_US96_.jpg`
    // Else, just load the "No Image Available" placeholder
    : "/assets/images/no-image-available.svg"
    const shoppingListItemClass = `inventory-list-item`
  
    return (
      <div className={shoppingListItemClass} onClick={() => localStorage.setItem('selectedInventory', productId)}>
        <img className="thumbnail" src={thumbnail} />
        <div className="title">{title}</div>
        <div className="props">
          <div className="prop">
            <label>ASIN</label>
            <div>{asin}</div>
          </div>
          <div className="prop">
            <label>SKU</label>
            <div>{sellerSku}</div>
          </div>
          <div className="prop">
            <label>QTY</label>
            <div>{newAfnTotalQuantity}</div>
          </div>
          <div className="prop">
            <label>Local Inv</label>
            <div>{localInventory ?? 0}</div>
          </div>
        </div>
      </div>
    )
}

export default InventoryListItem

import React, { useState, useEffect } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'

import Header from '../../components/header';
import Content from '../../components/content';
import SideNav from '../../components/side-nav';
import BottomNav from '../../components/bottom-nav';
import axios from 'axios'

import './logged-in.scss'
import { useQuery, useQueryClient } from 'react-query';

import { chooseDefaultCalculationPrice, GetProfitEstimations } from '../../utilities/functions';

const LoggedInContainer = ({ user, handleLogout, handleLoginSuccess }) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const dateNow = new Date()

  useEffect(() => {
    if (user && pathname === "/") history.push("/shopping-list")
  }, [user])

  useEffect(() => {
    localStorage.setItem('inventorySearch', '')
    localStorage.setItem('selectedInventory', '')
  }, [])
  

  const [ isUserMenuOpen, setIsUserMenuOpen ] = useState(false)
  const [ isNavOpen, setIsNavOpen ] = useState(false)

  const handleUserMenuToggle = () => {
    setIsUserMenuOpen(!isUserMenuOpen)
  }
  
  const handleNavToggle = () => {
    if (isNavOpen) setIsUserMenuOpen(false)
    setIsNavOpen(!isNavOpen)
  }
  const [isLongPress, setIsLongPress] = useState(false)
  const [selectedDelete, setSelectedDelete] = useState(null)
  const [deleteFunction, setDeleteFunction] = useState(null)

  //For inventory
  const [skip, setSkip] = useState(0)
  const [inventory, setInventory] = useState({ products: [] })

  const fetchInventory = async () => {
    return await axios.get(`/api/v1/inventory?limit=500&skip=${skip}`)

  }

  const queryClient = useQueryClient()
  const { data } = useQuery(['inventoryItems', skip], fetchInventory, {
    retry: 3,
    refetchOnWindowFocus: false,
    enabled: skip != undefined,
    onSuccess: ({ data }) => {
      const { nextIndex, products } = data
      const new_products = products.map(p => {
        const { profitCalculatedBy, listPrice, buyBoxPrice, lowestOfferPrice, lowestFBAOfferPrice, costPrice, fee, fulfilmentType, shipBy, shippingRate, prepCenterFees, weight, enrolledInSnL, fbmFee } = p
        const defaultPrice = chooseDefaultCalculationPrice({ profitCalculatedBy, listPrice, buyBoxPrice, lowestOfferPrice, lowestFBAOfferPrice })
        const updated_data = GetProfitEstimations({costPrice, defaultPrice, fee, fulfilmentType, shipBy, shippingRate, prepCenterFees, weight, enrolledInSnL, fbmFee});

        return {...p, ...updated_data, defaultPrice}
      })
      setSkip(nextIndex)
      setInventory(old => { return { products: [...old.products, ...new_products] } })
      if (nextIndex === undefined) {
        queryClient.cancelQueries(['inventoryItems', skip])
      }
    }
  })
  
  // States for manage my account details
  const [isRefetchCheckUserEnabled, setIsRefetchCheckUserEnabled] = useState(false)

  // States for cart
  const [newCartItems, setNewCartItems] = useState(localStorage.getItem('newCartItems') || 0)

  //States for packing
  const [openModal, setOpenModal] = useState(false)
  const [rows, setRows] = useState([])
  const [item, setItem] = useState({})

  const checkUser = async () => {
    return await axios.get('/api/v1/findUser')
  }

  const sendLoginRequest = async ({ email, password }) => {
    try {
      const response = await axios.post('/api/v1/login', { email, password })
      const { token, user } = await response.data

      handleLoginSuccess(token, user)
    }

    catch (error) {
      let errorMessage

      switch (error?.response?.status) {
        case 400: errorMessage = "Invalid credentials, please check your email or password"; break;
        case 500: errorMessage = "Server error, something went wrong on our end"; break;
        default: errorMessage = "Network error, please check your internet connection";
      }
    }
  }

  useEffect(() => {
    if (isRefetchCheckUserEnabled) {
      setTimeout(() => {
        setIsRefetchCheckUserEnabled(false)
      }, 360000)
    }
  }, [isRefetchCheckUserEnabled])


  const { data2 } = useQuery(
    ["checkUser"], 
    checkUser, 
    {
      enabled: isRefetchCheckUserEnabled,
      initialData: { user: [] },
      refetchInterval: 1000,
      refetchOnReconnect: false,
      retry: false,
      // onSuccess: ({ data }) => {
      //   const savedEmail = localStorage.getItem('email')
      //   const newEmail = localStorage.getItem('newEmail')
      //   const { user } = data
      // },
      onError: () => {
        const email = localStorage.getItem('newEmail')
        const password = localStorage.getItem('password')
        setIsRefetchCheckUserEnabled(false)
        sendLoginRequest({ email, password })
      }
    }
  )
  
  return (
    user
    ? <div className="logged-in">
        <Header 
          user={user} 
          handleNavToggle={handleNavToggle} 
          handleLogout={handleLogout} 
          isLongPress={isLongPress}
          setIsLongPress={setIsLongPress}
          setSelectedDelete={setSelectedDelete}
          setOpenModal={setOpenModal}
        />
        <SideNav
          state={{ user, isNavOpen, isUserMenuOpen, newCartItems }}
          handlers={{
            handleUserMenuToggle,
            handleNavToggle,
            handleLogout
          }}
        />
        <Content isLongPress={isLongPress} setIsLongPress={setIsLongPress} setDeleteFunction={setDeleteFunction} selectedDelete={selectedDelete} setSelectedDelete={setSelectedDelete} setIsRefetchCheckUserEnabled={setIsRefetchCheckUserEnabled} handleLogout={handleLogout} setNewCartItems={setNewCartItems} item={item} setItem={setItem} openModal={openModal} setOpenModal={setOpenModal} user={user} skip={skip} setSkip={setSkip} inventory={inventory} setInventory={setInventory}/>
        <BottomNav isLongPress={isLongPress} setIsLongPress={setIsLongPress} pathname={pathname} deleteFunction={deleteFunction} selectedDelete={selectedDelete} setSelectedDelete={setSelectedDelete} newCartItems={newCartItems}/>
      </div>
    : <Redirect to="/auth/login" />
  )
}

export default LoggedInContainer